import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NiComponentsModule } from '../ni-components/ni-components.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { CustomComponentsModule } from "../custom-components/custom-components.module";
import { PipesModule } from "../pipes/pipes.module";

import { PageLoginComponent } from './login/login.component';
import { PageDashboardComponent } from './dashboard/dashboard.component';
import { PageEquityPyramid } from './equity-pyramid/equity-pyramid.component';
import { PageTableTest } from './table-test/table-test.component';
import
{
  MatTabsModule
} from '@angular/material/tabs';
import { PageDashboard3dComponent } from './dashboard-3d/dashboard-3d.component';
import { PageDashboard2dComponent } from './dashboard-2d/dashboard-2d.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PageDashboard3dMultiComponent } from './dashboard-3d-multi/dashboard-3d-multi.component';
import { ChangePasswordPage } from './change-password/change-password';
import { PageHomeComponent } from './home/home.component';
import { PageDashboardcrossComponent } from './dashboard-cross/dashboard-cross.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NiComponentsModule,
    CustomComponentsModule,
    PipesModule,
    NgSelectModule,
    MatTabsModule,
    DragDropModule
  ],
  declarations: [
    PageLoginComponent,
    PageDashboardComponent,
    PageEquityPyramid,
    PageTableTest,
    PageDashboard3dComponent,
    PageDashboard2dComponent,
    PageDashboard3dMultiComponent,
    ChangePasswordPage,
    PageHomeComponent,
    PageDashboardcrossComponent
  ],
  exports: [
    PageLoginComponent,
    PageDashboardComponent,
    PageEquityPyramid,
    PageTableTest,
    PageDashboard3dComponent,
    PageDashboard2dComponent,
    PageDashboard3dMultiComponent,
    ChangePasswordPage,
    PageHomeComponent,
    PageDashboardcrossComponent
  ],
  entryComponents: [
  ]
})
export class PagesModule { }
