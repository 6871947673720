import { Component, OnInit, Input, Output } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { GenericService } from '../../providers/generic.service';
import { ChartService } from '../../providers/chart.service';
import { EventEmitter } from "@angular/core";
import { retry } from 'rxjs/operators';
import { FusionChartsService } from 'src/app/providers/fusion-charts.service';
import { DataService } from 'src/app/providers/data.service';
import { ExportToCsv } from 'export-to-csv';
import pptxgen from "pptxgenjs";

@Component({
  moduleId: module.id,
  selector: 'chart',
  templateUrl: 'chart.component.html',
  styleUrls: ['chart.component.scss'],
})
export class ChartComponent
{
  @Input() config: any;
  @Input() library: any;
  @Output() chartToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  chartVisible: boolean;
  round: any = Math.round;

  constructor(private chartService: ChartService)
  {
    this.chartVisible = true;
  }

  ngOnChanges()
  {
    this.config.tableForExport = this.config.tableForExport || this.config.table;
  }

  roundValue(dp): any
  {
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimals"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }

  roundBase(dp): any
  {    
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimalsBase"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }
  roundMean(value, precision) {
    
    return value.toFixed(precision);
  }
  roundTest(value, precision)
  {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  toggleChartToTable()
  {
    this.chartVisible = !this.chartVisible;
    this.config.tableForExport = this.config.tableForExport || this.config.table;
    this.config.mode = this.config.mode == 'table' ? 'chart' : 'table';
    this.chartToggleChange.emit(this.chartVisible);

  }

  transposeChart(config)
  {
    config.table = DataService.transposeTableNew(config.table);
    FusionChartsService.renderChartFromTableMain(config, true, config.SelectedBrands, config.SelectedStatements, config.selectedTabIndex);
  }

  exportToCsv()
  {
    this.config.tableForExport = this.config.tableForExport || this.config.table;
    //let decimals = this.config.chartOptions.decimals ? this.config.chartOptions.decimals:0;
    //let decimals = parseInt(FusionChartsService.defaultOptions["decimals"]);
    let count = this.config.tableForExport.Banners[0].ListDataPoint[0].ListValue.length;
    let data = count > 1 ? this.chartService.tableToDataTableNew2(this.config.tableForExport) : this.chartService.tableToDataTableNew(this.config.tableForExport);
    this.chartService.exportToCsv(data, this.config.title);
  }

  sortTableData(banner) {
    banner.sort = banner.sort && banner.sort == 'asc' ? 'desc' : 'asc';
    let desc = banner.sort == "desc" ? true : false;
    let bannerIndex = null;
    this.config.tableForExport.Banners.forEach((x, index) => {
      if (x.Id != banner.Id)
        x.sort = null;
      else
        bannerIndex = index;
    });
    if (this.config.tableForExport.Banners[0].ListDataPoint[0].ListValue.length > 1)
      this.config.tableForExport = this.chartService.sortedStubsByValueNew2(this.config.tableForExport, bannerIndex, desc);
    else
      this.config.tableForExport = this.chartService.sortedStubsByValueNew(this.config.tableForExport, bannerIndex, desc);
  }
  exportToPPT(config) {

    // 1. Create a new Presentation
    let pres = new pptxgen();
    // 2. Add a Slide
    let slide = pres.addSlide();
    // 3. Add one or more objects (Tables, Shapes, Images, Text and Media) to the Slide
    slide.addText(config.title, {
      x: 0.5,
      y: 0.1,
      w: "90%",
      h: 0.3,
      margin: 0.5,
      fontFace: "Arial",
      fontSize: 10,
      color: "333333",
      bold: false,
      fill: { color: "e9ecef" },
      align: pres.AlignH.center
    });

    let type = config.type == 'msline' ? 'line' : 'bar';

    let dataChartAreaLine = [];
    let arrChartColors = [];

    config.dataFc.dataSet.forEach((x, i) => {
      //arrChartColors.push(x.color.replace("#", ""));
      let obj: any = { labels: [], values: [] };
      obj.name = x.seriesName;
      config.dataFc.categories[0].category.forEach((y, j) => {
        obj["labels"].push(y.label);
        obj["values"].push(config.dataFc.dataSet[i].data[j].value);
      });
      dataChartAreaLine.push(obj);
    });

    let hWidth = 0;
    let tempWidth = dataChartAreaLine[0]["labels"].length * 1.5;
    if (tempWidth <= 10)
      hWidth = (10 - tempWidth) / 2;
    else
      tempWidth = 10;

    let chartOptions: any = {
      x: hWidth, y: .3, w: tempWidth, h: 5, catAxisLabelRotate: 0, catAxisLabelFontSize: 7
      , valAxisHidden: true, showLegend: true, legendPos: 'b', showValue: true, title: config.title
      , showTitle: false, titleFontSize: 12, valAxisLabelFontSize: 8, dataLabelFontSize: 8, dataLabelPosition: 'b', valAxisMaxVal:100, valGridLine: {
        style: 'none'
      }
      //,chartColors: arrChartColors
      , align: pres.AlignH.center
    };
    if (type == 'bar')
      chartOptions["barGrouping"] = 'stacked';
    slide.addChart(pres.ChartType[type], dataChartAreaLine, chartOptions);
    // 4. Save the Presentation
    pres.writeFile("download");

  }
}
