import { NgModule } from '@angular/core';
import { FilterPipe } from './filter.pipe';
import { YesNoPipe } from './yes-no.pipe';
import { FormatPipe } from './format.pipe';
import { EqualPipe } from './equal.pipe';

@NgModule({
    imports: [],
    declarations: [FilterPipe, YesNoPipe, FormatPipe, EqualPipe],
    exports: [FilterPipe, YesNoPipe, FormatPipe, EqualPipe],
    providers: [EqualPipe] 
})

export class PipesModule {}
