import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

import { MENU } from '../../../../settings/menu';
import { GlobalService } from '../../../providers/global.service';
import { MasterService } from '../../../providers/master.service';
import { StorageService } from '../../../providers/storage.service';
import { GenericService } from 'src/app/providers/generic.service';
import { AuthService } from 'src/app/providers/auth.service';

@Component({
  moduleId: module.id,
  selector: 'horizontal-navbar',
  templateUrl: 'horizontal-navbar.component.html',
  styleUrls: ['horizontal-navbar.component.scss'],
  host: {
    '[class.app-navbar]': 'true',
    '[class.show-overlay]': 'showOverlay'
  }
})

export class HorizontalNavbarComponent
{
  @Input() title: string;
  @Input() openedSidebar: boolean;
  @Output() sidebarState = new EventEmitter();
  showOverlay: boolean;
  gs = GlobalService;
  activeLink: any = "Category penetration";
  country: any;
  menuItems;
  category: any;
  myuser = AuthService.getUser();
  constructor(private _elRef: ElementRef, private router: Router, private masterService: MasterService)
  {
    this.openedSidebar = true;
    this.showOverlay = false;

    //path = path.substring(path.lastIndexOf("/") + 1);

  }

  async ngOnInit() {

    this.gs.filtersObservable.subscribe((ft: any[]) => {

    });
    this.menuItems = GenericService.clone(MENU);
    let path = GenericService.getCurrentPath();

    var filtersGlobal: any;
    var ff = this.gs.filters;
    this.gs.filtersObservable.subscribe(() => {
      filtersGlobal = GlobalService.serializeFiltersNew("1");
      this.gs.filters.forEach(f => {
        if (f.GroupName == "Category" && f.Visible == true)
          this.category = f.Selected;
      }
      );
      this.country = filtersGlobal.split(':')[1];
    });


    //setInterval(() => {
    //  filtersGlobal = [(Math.floor(Math.random() * (1000000 - 9999999)) + 9999999).toString(), GlobalService.serializeFiltersNew("1")];
    //}, 10000)

    //this.country = filtersGlobal.split(':')[1];
    //this.country = "1";

    //setTimeout(() => {
    //  filtersGlobal = [(Math.floor(Math.random() * (1000000 - 9999999)) + 9999999).toString(), GlobalService.serializeFiltersNew("1")].toString();
    //  this.country = filtersGlobal.split(':')[1];
    //}, 10000)

    this.menuItems.forEach(x => {
      if (x.sub) {
        x.sub.forEach(y => {
          if (y.routing == path)
            this.activeLink = x.title;
        });
      }
      else {
        if (x.routing == path)
          this.activeLink = x.title;
      }
    });

  }

  linkIsActive(sub)
  {
    let active = (sub.filter(x => x.routing == this.activeLink).length > 0);
    return active;
    //return (sub.filter(x => x.routing == this.activeLink).length > 0);
  }

  open(event)
  {
    let clickedComponent = event.target.closest('.nav-item');
    let items = clickedComponent.parentElement.children;

    event.preventDefault();

    for (let i = 0; i < items.length; i++)
    {
      items[i].classList.remove('opened');
    }
    clickedComponent.classList.add('opened');

    //Add class 'show-overlay'
    this.showOverlay = true;
  }

  close(event)
  {
    let clickedComponent = event.target;
    let items = clickedComponent.parentElement.children;

    event.preventDefault();

    for (let i = 0; i < items.length; i++)
    {
      items[i].classList.remove('opened');
    }

    //Remove class 'show-overlay'
    this.showOverlay = false;
  }

  openSidebar()
  {
    this.openedSidebar = !this.openedSidebar;
    this.sidebarState.emit();
  }

  logout()
  {
    StorageService.remove('breadCrumb');
    StorageService.remove('user');
    this.router.navigate(['/login']);
  }

  changePassword() {
  
    this.router.navigate(['/change-password']);
  }

  fnLinkClicked(menu)
  {
    this.activeLink = menu.title;
    GlobalService.showHideFilters(this.activeLink);
  }

  filterChange(filter)
  {
      GlobalService.filterChanged(filter);
      GlobalService.emitCategoryChanges();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    this.country = filtersGlobal.split(':')[1];
    this.category = filter.Selected;
    //alert(JSON.stringify(filter));

  }
}
