import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { environment } from '../../environments/environment';
import { GenericService } from './generic.service';
import { StorageService } from './storage.service';


@Injectable()
export class MasterService
{
  jwtHelper: JwtHelperService = new JwtHelperService();
  constructor(private http: HttpClient)
  {

  }

  setUser(data): any
  {
    let user = this.jwtHelper.decodeToken(data.access_token);
    user.access_token = data.access_token;
    user.refresh_token = data.refresh_token;
    user.DefaultLocation = user.DefaultLocation ? JSON.parse(user.DefaultLocation) : null;
    user.Locations = user.Locations ? JSON.parse(user.Locations) : [];
    user.Roles = user.Roles ? JSON.parse(user.Roles) : [];
    user.OrganogramIds = user.OrganogramIds ? JSON.parse(user.OrganogramIds) : [];
    user.Permissions = [];

    for (var i = 0; i < user.Roles.length; i++)
    {
      for (var j = 0; j < user.Roles[i].Permissions.length; j++)
      {
        user.Permissions.push(user.Roles[i].Permissions[j]);
      }
    }
    StorageService.set('user', user);
    return user;
  }

  callService1(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
  {
    let headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
      });

    //As per documentation of angular-jwt, access_token should have been appended automatically. But not working that why we added it manually
    //Will check it later on. And also need to see how refresh_tokens will work
    if (!skipAuth && !environment["skipAuth"])
    {
      var user = StorageService.get('user');
      if (user)
      {
        headers = headers.set("Authorization", "Bearer " + user.access_token);
      }
    }
    if (method == 'Get')
    {
      return this.http.get(environment.baseUrl + serviceUrl, { headers: headers }).pipe(
        map((response) =>
        {
          return response;
        }),
        catchError(this.handleError)
      );
    }
    else
    {
      if (skipAuth) {
        return this.http.post(environment.baseUrl + serviceUrl, data).pipe(
          map((response) => {
            return response;
          }),
          catchError(this.handleError)
        );
      }
      else {
        return this.http.post(environment.baseUrl + serviceUrl, data, { headers: headers }).pipe(
          map((response) => {
            return response;
          }),
          catchError(this.handleError)
        );
      }
    }
  }

  callService(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
  {
    skipAuth = skipAuth || environment["skipAuth"];
    return this.callService1(serviceUrl, skipAuth, method, data);
  }

  callGetService(serviceUrl: string, skipAuth: boolean = false): Observable<any>
  {
    return this.callService(serviceUrl, skipAuth, 'Get');
  }
  callPostService(serviceUrl: string, skipAuth: boolean = false): Observable<any>
  {
    return this.callService(serviceUrl, skipAuth, 'Post');
  }

  getList(serviceName: string = null, pageNo: number = null, pageSize: number = null, orderBy: string = null, filter: string = null, excel: boolean = false): Observable<any>
  {
    pageNo = pageNo || 1;
    pageSize = pageSize || 50;
    filter = encodeURIComponent(GenericService.nullToEmpty(filter));
    var url = serviceName;
    if (url.indexOf('/') == -1)
      url += '/list';

    url = GenericService.addToQueryString(url, "pageNo", pageNo);
    url = GenericService.addToQueryString(url, "pageSize", pageSize);
    url = GenericService.addToQueryString(url, "orderBy", orderBy);
    url = GenericService.addToQueryString(url, "filter", filter);
    return this.callGetService(url);
  }

  getDetails(serviceName: string, recordId: any, key: string): Observable<any>
  {
    key = key || 'id';
    return this.callGetService(serviceName + '/details?' + key + '=' + recordId);
  }

  fetchData(serviceName: string, filter: string = null): Observable<any>
  {
    filter = encodeURIComponent(GenericService.nullToEmpty(filter));
    return this.callGetService(serviceName + "?filter=" + filter);
  }

  bindDropDown(masterName, filterStr = null, locationID = 0, pageNo = 0, pageSize = 0)
  {
    filterStr = filterStr ? "Name:" + filterStr : '';
    var serviceName = masterName;
    return this.getList(serviceName, 0, 0, null, filterStr);
  }

  postData(serviceUrl: string, data: any, skipAuth: boolean = false): Observable<any>
  {
    return this.callService(serviceUrl, skipAuth, 'Post', data);
  }

  save(serviceUrl: string, data: any): Observable<any>
  {
    return this.postData(serviceUrl + '/save', data);
  }

  public handleError(error: HttpResponse<any> | any)
  {
    var errMsg = fetchErrorMessage(error);
    console.log(errMsg);
    return observableThrowError(errMsg);
  }

  public fetchErrorMessage(error: HttpResponse<any> | any)
  {
    return fetchErrorMessage(error);
  }
}

function fetchErrorMessage(error: HttpResponse<any> | any)
{
  let errMsg: string;
  if (error instanceof HttpResponse)
  {
    if (error.status == 0)
    {
      errMsg = "Could not connect to server";
    }
  }
  else if (typeof error === 'string')
  {
    errMsg = error;
  }
  else
  {
    errMsg = error.message || error.body || error.toString();
  }
  return errMsg;
}
