import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { finalize } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { MENU } from '../../../../settings/menu';
import { GenericService } from '../../../providers/generic.service';
import { StorageService } from '../../../providers/storage.service';
import { MasterService } from 'src/app/providers/master.service';
import { GlobalService } from 'src/app/providers/global.service';


@Component({
  moduleId: module.id,
  selector: 'vertical-navbar',
  templateUrl: 'vertical-navbar.component.html',
  styleUrls: ['vertical-navbar.component.scss'],
  host: { 'class': 'vertical-navbar' }
})
export class VerticalNavbarComponent
{
  path;
  menuItems;
  subscription;
  gs = GlobalService;
  loading;
  loadingExcel;
  ajaxSubscription: any;

  constructor(private router: Router, private masterService: MasterService)
  {
    //console.log(JSON.stringify(this.gs.filters));
  }

  ngOnInit()
  {
    this.filterMenuItems();
  }

  filterMenuItems()
  {
    let items = GenericService.clone(MENU);
    this.menuItems = items.filter(x => !x.pages || x.pages.indexOf(GlobalService.path) > -1);

    this.menuItems.forEach(x =>
    {
      if (x.sub)
        x.sub = x.sub.filter(y => !y.pages || y.pages.indexOf(GlobalService.path) > -1);
    });
  }

  open(path)
  {
    if (path)
      this.path = null;
    else
      path = this.path;

    this.router.navigate([path]);
  }

  exportToPPT()
  {
    //let wavesArr = GlobalService._filters.filter(x => x.Id == "Wave")[0].Selected;
    //let wavesStr = GenericService.toCsv(wavesArr, null, ",");
    var breadCrumb = StorageService.get("breadCrumb") || [{ Id: "1", Name: "ADCB" }];
    var orgID = breadCrumb[breadCrumb.length - 1].Id;
    var url = "create-ppt?";
    var filters = GlobalService.serializeFiltersNew();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    if (GlobalService.bank == 'ahb')
      filters += ';Bank=4';
    url += "filters=" + filters;
    url += "&args=organogram:" + orgID;

    //this.config.filters = filters;
    //this.config.loading = true;
    //this.config.args = "organogram:" + this.breadCrumb[this.breadCrumb.length - 1].Id;

    this.loading = true;
    this.ajaxSubscription = this.masterService.callGetService(url).pipe(
      finalize(() =>
      {
        this.loading = false;
      }))
      .subscribe(fileName =>
      {
        location.href = environment.baseUrl + "download-ppt?fileName=" + fileName;
      },
        (err) =>
        {
          this.loading = false;
          console.log(err);
        });
  }

  exportToExcel()
  {
    //let wavesArr = GlobalService._filters.filter(x => x.Id == "Wave")[0].Selected;
    //let wavesStr = GenericService.toCsv(wavesArr, null, ",");
    var breadCrumb = StorageService.get("breadCrumb") || [{ Id: "1", Name: "ADCB" }];
    var orgID = breadCrumb[breadCrumb.length - 1].Id;
    var url = "create-excel?";
    var filters = GlobalService.serializeFiltersNew();
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    if (GlobalService.bank == 'ahb')
      filters += ';Bank=4';
    url += "filters=" + filters;
    url += "&args=organogram:" + orgID;

    //this.config.filters = filters;
    //this.config.loading = true;
    //this.config.args = "organogram:" + this.breadCrumb[this.breadCrumb.length - 1].Id;

    this.loadingExcel = true;
    this.ajaxSubscription = this.masterService.callGetService(url).pipe(
      finalize(() =>
      {
        this.loadingExcel = false;
      }))
      .subscribe(fileName =>
      {
        location.href = environment.baseUrl + "download-excel?fileName=" + fileName;
      },
        (err) =>
        {
          this.loadingExcel = false;
          console.log(err);
        });
  }


  filterChange(filter)
  {
    //if (filter.Change && GlobalService[filter.Change])
    //  GlobalService[filter.Change](this.masterService);

    GlobalService.filterChanged(filter);
  }

  applyFilters()
  {
    GlobalService.emitFilterChanges();
  }
}
