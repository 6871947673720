import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, finalize, retry } from 'rxjs/operators';
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from 'src/app/providers/generic.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
//import { CHART_CONFIGS } from 'src/settings/chart-config';


@Component({
  moduleId: module.id,
  selector: 'page-dashboard-3d-multi',
  templateUrl: './dashboard-3d-multi.component.html',
  styleUrls: ['./dashboard-3d-multi.component.scss']
})
export class PageDashboard3dMultiComponent implements OnDestroy
{
  showManageChartDimension = false;
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;

  user: any;
  config: any = [
    ,
    {
      PageUrl: "mshare",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: true, chartType: 'stacked', service: "Vol-jairam?groupId=191", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: true, chartType: 'stacked', service: "Vol-jairam?groupId=192", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    
    {
      PageUrl: "monthlyusers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            //{ type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', service: "plznew-jairam?groupId=65", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [], defaultFilters: [0, 1, 4] }
            {
              type: 'stackedcolumn2d', showdim: false, base: ' monthly active users', chartType: 'stacked', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', service: "plz-jairam?groupId=65", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: []
            }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, base: ' monthly active users', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=81", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
        ,
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, base: ' monthly active users', ttl: 'Q: Have you used any of the following express delivery brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=83", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, base: ' monthly active users', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST ONE MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=85", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "weeklyusers",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            {
              type: 'stackedcolumn2d', showdim: false, base: ' weekly active users', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=66", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: []
            }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, base: ' weekly active users', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=82", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        ,
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, base: ' weekly active users', ttl: 'Q: Have you used any of the following express delivery brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=84", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        ,
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, base: ' weekly active users', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST ONE WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plz-jairam?groupId=86", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }

      ]
    },
    {
      PageUrl: "bf-blockersP1M",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{
            type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=58", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {}
          }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=60", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=121", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        }
      ]
    },
    {
      PageUrl: "bf-blockers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=59", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {} }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=61", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        }
      ]
    },
    {
      PageUrl: "Satisfaction",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', service: "plz-jairam?groupId=46", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', service: "plz-jairam?groupId=48", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', service: "plz-jairam?groupId=50", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', service: "plz-jairam?groupId=52", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "5"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following digital peyment brand?', service: "plz-jairam?groupId=314", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "6"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', service: "plz-jairam?groupId=416", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "11"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following 2-wheels online ride hailing service?', service: "plz-jairam?groupId=616", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "12"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: In your opinion, how agree/disagree are you if this ad wish to deliver these messages?', service: "plz-jairam?groupId=516", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', service: "plz-jairam?groupId=816", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
      ]
    },
    {
      PageUrl: "SatisfactionT2b",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=87", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=101", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=105", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=88", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=102", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=106", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=89", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=103", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=107", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=90", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=104", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=108", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "experience-association",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=47", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=49", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=51", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    }
    , {
      PageUrl: "communication-metrics-ada",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=56", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands have you seen or heard an advertisement for in the PAST TWO WEEKS? Please select all that apply.', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, }
          ]
        }
      ]
    },
    {
      PageUrl: "communication-metrics-bs",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            {
              type: 'stackedcolumn2d', showdim: false, chartType: 'stacked', service: "plz-jairam?groupId=57", base: " ", ttl: "Q: Have you recently spoken about the brand with anyone? 1) Yes, I have recently spoken POSITIVELY about the brand 2) Yes, I have recently spoken NEGATIVELY about the brand 3) Yes, I have recently spoken about the brand but NEITHER POSITIVE NOR NEGATIVE 4) No, I haven't recently spoken about the brand", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [], hideFilter: false,
            }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=54", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bc",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=55", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following characteristics do you associate with ...?', basecolum: true, mode: 'table', tbview: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    }
  ];


  round: any = Math.round;
  toInt: any = parseInt;

  SelectedCategory: any;
  SelectedConfig: any;
  SelectedPageConfig: any = [];

  chartCategories: any = [];
  chartSelectedCategories: any;

  chartFilters: any = [];
  chartSelectedFilters: any;

  initialLoad: any = false;
  filterLabels: any;

  waves: any = [];
  SelectedWave: any;

  axesValue = [
  ];

  axes = [
    { value: 'Category(x-Axis)', disabled: true },
    { value: 'Series(Y-Axis)', disabled: true },
    { value: 'Filters', disabled: true }
  ];

  drop(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.axesValue, event.previousIndex, event.currentIndex);
    this.changeDimension();
  }

  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute)
  {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.subscription.push(GlobalService.filtersObservable.subscribe(filters =>
    {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
    }));
    this.subscription.push(GlobalService.categoryObservable.subscribe(category =>
    {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      this.run();
    }));
    this.subscription.push(GlobalService.chartOptionsObservable.subscribe(option =>
    {
      this.run();
    }));
    this.subscription.push(GlobalService.chartTransposeObservable.subscribe(chartConfig =>
    {
      let tempAxesValue = JSON.parse(JSON.stringify(this.axesValue));
      this.axesValue[0] = tempAxesValue[1];
      this.axesValue[1] = tempAxesValue[0];
      this.transposeChart(chartConfig);
    }));
  }

  ngOnInit()
  {
    this.route.params.subscribe(params =>
    {
      this.chartCategories = [];
      this.chartFilters = [];
      let id = params["id"] ? params["id"] : "1";
      let pageConfig = this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];
      this.SelectedPageConfig = pageConfig;
      if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
        this.run();
    });
  }

  transposeChart(chartConfig)
  {    
    chartConfig.tableForExport = this.getFilteredTable(chartConfig.table, this.axesValue[0], this.axesValue[1], this.axesValue[2], chartConfig.index);
    FusionChartsService.renderChartFromTableMultiDimension(chartConfig, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], null, chartConfig.index);
  }

  changeDimension()
  {
    let config = this.SelectedConfig;
    config.Charts = [];
    this.fillCategoryFilterDropDowns(config.table);
    this.chartFilters.forEach((x, i) =>
    {
      let chart: any = {};
      chart["Multi"] = true;
      chart.id = 'chart' + i;
      chart.index = i;
      chart.table = config.table;
      chart.title = x.Name;
      let keys = Object.keys(config);
      keys.forEach(key =>
      {
        if (key != "Charts" && key != "id" && key != "title")
          chart[key] = config[key];
      });
      chart.tableForExport = this.getFilteredTable(chart.table, this.axesValue[0], this.axesValue[1], this.axesValue[2], i);
      FusionChartsService.renderChartFromTableMultiDimension(chart, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], null, i);
      config.Charts.push(chart);
    });

  }

  reloadChart()
  {
    let config = this.SelectedConfig;
    config.Charts = [];
    this.chartFilters.forEach((x, i) =>
    {
      if (this.chartSelectedFilters.length == 0 || this.chartSelectedFilters.includes(i))
      {
        let chart: any = {};
        chart["Multi"] = true;
        chart.id = 'chart' + i;
        chart.index = i;
        chart.table = config.table;
        chart.title = x.Name;
        let keys = Object.keys(config);
        keys.forEach(key =>
        {
          if (key != "Charts" && key != "id" && key != "title" && key !="tableForExport")
            chart[key] = config[key];
        });
        chart.tableForExport = this.getFilteredTable(chart.table, this.axesValue[0], this.axesValue[1], this.axesValue[2], i);
        FusionChartsService.renderChartFromTableMultiDimension(chart, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], null, i);
        config.Charts.push(chart);
      }
    });
  }

  public run()
  {
    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"][0]));
    else
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig[0]["Configs"][0]));

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    this.filterLabels = GlobalService.getFilterString(true);

    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();

    let config = this.SelectedConfig;
    //config.id = 'chart' + i;       
    config.filters = filters;
    config.filtersGlobal = filtersGlobal;
    config.filtersAdditional = filtersAdditional;
    config.filtersgroup = filtersGlobalgroup;
    config.filtersGlobalOthers = filtersGlobalOthers;
    config.loading = true;
    config.Charts = [];
    this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
      finalize(() =>
      {
        config.loading = false;
      }))
      .subscribe((table) =>
      {
        config.table = table;
        //alert(config.showdim);
        config.loading = false;
        this.axesValue = [];

        this.axesValue.push("Waves");
        this.axesValue.push("Statements");
        this.axesValue.push("Brands");
       
        this.fillCategoryFilterDropDowns(table);

        this.chartFilters.forEach((x, i) =>
        {
          let chart: any = {};
          chart["Multi"] = true;
          chart.id = 'chart' + i;
          chart.index = i;
          chart.title = x.Name;
          let keys = Object.keys(config);
          keys.forEach(key =>
          {
            if (key != "Charts" && key != "id" && key != "title")
              chart[key] = config[key];
          });
          chart.tableForExport = this.getFilteredTable(table, this.axesValue[0], this.axesValue[1], this.axesValue[2], i);
          FusionChartsService.renderChartFromTableMultiDimension(chart, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], null, i);
          config.Charts.push(chart);
        });
      },
        (err) =>
        {
          console.log(err);
        });

  }

  fillCategoryFilterDropDowns(table)
  {
    this.chartCategories = [];
    this.chartFilters = [];
    let catVar = this.axesValue[0];
    table[catVar].forEach((x, i) =>
    {
      this.chartCategories.push({ Name: x.Name, Value: i });
    });

    let fltrVar = this.axesValue[2];
    table[fltrVar].forEach((x, i) =>
    {
      this.chartFilters.push({ Name: x.Name, Value: i });
    });
  }

  getSelectedCategory()
  {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  getFilteredTable(table, categoryName, seriesName, filterBy, fltrIndex)
  {
    let cloneTable = JSON.parse(JSON.stringify(table));
    let categoryIndexVar = categoryName.substring(0, categoryName.length - 1) + "Index";
    let seriesIndexVar = seriesName.substring(0, seriesName.length - 1) + "Index";
    let fltrIndexVar = filterBy.substring(0, filterBy.length - 1) + "Index";

    let tempTable: any = { Brands: [], Waves: [], Statements: [], Data: [] };
    cloneTable[categoryName].forEach(x =>
    {
      tempTable.Brands.push(x);
    });
    cloneTable[seriesName].forEach(x =>
    {
      tempTable.Waves.push(x);
    });
    let filterData = cloneTable.Data.filter(x => x[fltrIndexVar] == fltrIndex);
    filterData.forEach(x =>
    {
      tempTable.Data.push(x);
      let cloneX = JSON.parse(JSON.stringify(x));
      x["BrandIndex"] = cloneX[categoryIndexVar];
      x["WaveIndex"] = cloneX[seriesIndexVar];
      x["StatementIndex"] = 0;
    });
    return tempTable;
  }

  ngOnDestroy()
  {
    this.subscription.forEach(x =>
    {
      x.unsubscribe();
    });
  }
}
