import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { RouterModule } from '@angular/router';
import { NiComponentsModule } from '../ni-components/ni-components.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';

import { HorizontalNavbarComponent } from './components/horizontal-navbar/horizontal-navbar.component';
import { VerticalNavbarComponent } from './components/vertical-navbar/vertical-navbar.component';
import { AdditionNavbarComponent } from './components/addition-navbar/addition-navbar.component';
import { LogoComponent } from './components/logo/logo.component';
import { MenuComponent } from './components/menu/menu.component';
import { FooterComponent } from './components/footer/footer.component';
import { CustomComponentsModule } from '../custom-components/custom-components.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import
{
  MatSlideToggleModule
} from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    HorizontalNavbarComponent,
    VerticalNavbarComponent,
    AdditionNavbarComponent,
    LogoComponent,
    MenuComponent,
    FooterComponent
  ],
  exports: [
    HorizontalNavbarComponent,
    VerticalNavbarComponent,
    AdditionNavbarComponent,
    LogoComponent,
    MenuComponent,
    FooterComponent
  ],
  imports: [
    HttpModule,
    CommonModule,
    FormsModule,
    RouterModule,
    NiComponentsModule,     
    NgSelectModule,
    ClickOutsideModule,
    NgbModule,
    MatSlideToggleModule
  ]
})
export class UIModule { }
