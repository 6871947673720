import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from 'src/app/providers/generic.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
//import { CHART_CONFIGS } from 'src/settings/chart-config';

@Component({
  moduleId: module.id,
  selector: 'page-dashboard-3d',
  templateUrl: './dashboard-3d.component.html',
  styleUrls: ['./dashboard-3d.component.scss']
})
export class PageDashboard3dComponent implements OnDestroy
{
  showManageChartDimension = false;
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  showmetrics: any = false;
  user: any;
  filterLabels: any;
  config: any = [
    {
      
      PageUrl: "experience-association",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=47", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=49", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=51", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {

      PageUrl: "experience-associationnew1",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2741", base: 'Base: Past 1 month users', ttl: 'Q: Top 2 Box - Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
                      ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2742", base: 'Base: Past 1 month users', ttl: 'Q: Top 2 Box - Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            
          ]
        }
      ]
    }, {

      PageUrl: "experience-associationnew2",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2743", base: 'Base: Past 1 month users', ttl: 'Q: Bottom 2 Box - Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2744", base: 'Base: Past 1 month users', ttl: 'Q: Bottom 2 Box - Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            ]
        }
      ]
    }, {

      PageUrl: "experience-associationnew3",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2741", base: 'Base: Past 1 month users', mean: true, ttl: 'Q: Top 2 Box - Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2742", base: 'Base: Past 1 month users', mean: true, ttl: 'Q: Top 2 Box - Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            ]
        }
      ]
    },
    {
      PageUrl: "aided-awareness",
      DefaultCategory: 5
      , ChartConfigs: [
        {
          Category: "5"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=311", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of digital payment feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "6"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=414", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of food feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "12"
          , Configs: [

            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=511", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of transport feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "11"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=611", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of groceries feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "14"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=711", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of supper app feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "15"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=811", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of logistic feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },

      ]
    },
    {
      PageUrl: "p1m-usage",
      DefaultCategory: 5
      , ChartConfigs: [
        {
          Category: "5"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=312", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "6"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=415", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "12"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=512", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "11"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=612", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=812", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },

      ]
    },


    {
      
      PageUrl: "UseCase",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2410", base: 'Base: Those Answering', ttl: 'occasions ', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2411", base: 'Base: Those Answering', ttl: 'occasions ', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brandperceptionbi",
      DefaultCategory: 5
      , ChartConfigs: [
        {
          Category: "5"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=309", base: 'Base: Past 1 month users', ttl: 'Q: Below are a number of statements related to Digital payment. Please select the brand(s) that you think suit each statement. We would like to know your impressions of these brands, whether or not youâ€™ve tried them yourself.You can select more than one brand for each statement.', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "6"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=409", base: 'Base: Past 1 month users', ttl: 'Q: Below are a number of statements related to Food. Please select the brand(s) that you think suit each statement. We would like to know your impressions of these brands, whether or not youâ€™ve tried them yourself.You can select more than one brand for each statement.', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "11"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=609", base: 'Base: Past 1 month users', ttl: 'Q: Below are a number of statements related to transport. Please select the brand(s) that you think suit each statement. We would like to know your impressions of these brands, whether or not youâ€™ve tried them yourself.You can select more than one brand for each statement.', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "12"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=509", base: 'Base: Past 1 month users', ttl: 'Q: Below are a number of statements related to groceries. Please select the brand(s) that you think suit each statement. We would like to know your impressions of these brands, whether or not youâ€™ve tried them yourself.You can select more than one brand for each statement.', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "14"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=709", base: 'Base: Past 1 month users', ttl: 'Q: Below are a number of statements related to gojek supper app. Please select the brand(s) that you think suit each statement. We would like to know your impressions of these brands, whether or not youâ€™ve tried them yourself.You can select more than one brand for each statement.', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "15"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=809", base: 'Base: Past 1 month users', ttl: 'Q: Below are a number of statements related to logistic. Please select the brand(s) that you think suit each statement. We would like to know your impressions of these brands, whether or not youâ€™ve tried them yourself.You can select more than one brand for each statement.', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }
            }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bo",
      DefaultCategory: 5
      , ChartConfigs: [
        {
          Category: "5"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=310", base: 'Base: Past 1 month users', ttl: 'Q: Which Digital payment do you use when face following situation? You can choose more than one brand if apply, or choose none', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "6"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=410", base: 'Base: Past 1 month users', ttl: 'Q: Which food do you use when face following situation? You can choose more than one brand if apply, or choose none', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "11"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=610", base: 'Base: Past 1 month users', ttl: 'Q: Which transport do you use when face following situation? You can choose more than one brand if apply, or choose none', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "12"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=510", base: 'Base: Past 1 month users', ttl: 'Q: Which groceries do you use when face following situation? You can choose more than one brand if apply, or choose none', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "14"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=710", base: 'Base: Past 1 month users', ttl: 'Q: Which supper app do you use when face following situation? You can choose more than one brand if apply, or choose none', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=810", base: 'Base: Past 1 month users', ttl: 'Q: Which logistic do you use when face following situation? You can choose more than one brand if apply, or choose none', basecolum: true, tbview: true, mode: 'T', transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=54", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "featureusage",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2703", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following ... features have you used in the past 1 month?', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2713", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following ... features have you used in the past 1 month?', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "featureawareness",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2704", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Please select all of the features that is available for the stated brand which you are aware of. Regardless of whether you have used them or not, please select all the feature that you are aware of', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2714", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Please select all of the features that is available for the stated brand which you are aware of. Regardless of whether you have used them or not, please select all the feature that you are aware of', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi-new",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2705", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi-user",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2701", base: "Base: Respondents User/Non User of the brand", ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi-usernew",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2721", base: "Base: Respondents User/Non User of the brand", ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "sources-ada",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2702", base: "Base: Respondents who're aware of the brand Ad", ttl: 'Where did you see/hear advertisement of ...?', basecolum: true, tbview: true, mode: 'T', chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bc",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=55", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following characteristics do you associate with ...?', basecolum: true, mode: 'T', tbview: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bcnew",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=2706", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following characteristics do you associate with ...?', basecolum: true, mode: 'T', tbview: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "bf-blockersP1M",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{
            type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plz-jairam?groupId=171", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {}, mode: 'T'
          }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plz-jairam?groupId=173", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {}, mode: 'T' }]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plz-jairam?groupId=175", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {}, mode: 'T' }]
        }
      ]
    },
    {
      PageUrl: "bf-blockers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "plz-jairam?groupId=172", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {}, mode: 'T' }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "plz-jairam?groupId=174", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {}, mode: 'T' }]
        }
      ]
    },
  ];

  round: any = Math.round;
  toInt: any = parseInt;

  SelectedCategory: any;
  SelectedConfig: any;
  SelectedPageConfig: any = [];

  chartCategories: any = [];
  chartSelectedCategories: any;

  chartFilters: any = [];
  chartSelectedFilters: any;
  initialLoad: any = false;

  waves: any = [];
  SelectedWave: any;

  axesValue = [
  ];

  axes = [
    { value: 'Category(x-Axis)', disabled: true },
    { value: 'Series(Y-Axis)', disabled: true },
    { value: 'Filters', disabled: true }
  ];

  lstSortBy: any = [];
  selectedSortBy: any;

  lstSortByItems: any = [];
  selectedSortByItem: any;

  lstSortOrder: any = [];

  drop(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.axesValue, event.previousIndex, event.currentIndex);
    this.changeDimension();
  }

  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute)
  {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.subscription.push(GlobalService.filtersObservable.subscribe(filters =>
    {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
    }));
    this.subscription.push(GlobalService.categoryObservable.subscribe(category =>
    {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      this.run();
    }));
    this.subscription.push(GlobalService.chartOptionsObservable.subscribe(option =>
    {
      this.run();
    }));
    this.subscription.push(GlobalService.chartTransposeObservable.subscribe(option =>
    {
      let tempAxesValue = JSON.parse(JSON.stringify(this.axesValue));
      this.axesValue[0] = tempAxesValue[1];
      this.axesValue[1] = tempAxesValue[0];
      this.changeDimension();
    }));
  }

  ngOnInit()
  {
    this.route.params.subscribe(params =>
    {
      this.chartCategories = [];
      this.chartFilters = [];

      let id = params["id"] ? params["id"] : "1";
      let pageConfig = this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];
      this.SelectedPageConfig = pageConfig;
      if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
        this.run();
    });
  }

  changeDimension()
  {
    let config = this.SelectedConfig;
    this.fillCategoryFilterDropDowns(config.table);
    FusionChartsService.renderChartFromTableMultiDimension(config, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], this.chartSelectedCategories, this.chartSelectedFilters);
  }

  reloadChart()
  {
    let config = this.SelectedConfig;
    FusionChartsService.renderChartFromTableMultiDimension(config, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], this.chartSelectedCategories, this.chartSelectedFilters);
  }

  public run()
  {
    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"][0]));
    else
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig[0]["Configs"][0]));

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();
    var filtersCross = GlobalService.serializeFiltersNew("5");
    var filtersNext = GlobalService.serializeFiltersNew("4");

    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();

    let config = this.SelectedConfig;    
    config.filters = filters;
    config.filtersGlobal = filtersGlobal;
    config.filtersAdditional = filtersAdditional;
    config.filtersCross = filtersCross;
    config.filtersNext = filtersNext;
    config.filtersgroup = filtersGlobalgroup;
    config.filtersGlobalOthers = filtersGlobalOthers;
    this.filterLabels = GlobalService.getFilterString(true);
    config.loading = true;
    this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
      finalize(() =>
      {
        config.loading = false;
      }))
      .subscribe((table) =>
      {
        config.loading = false;
        this.axesValue.push("Brands");
        this.axesValue.push("Waves");
        this.axesValue.push("Statements");

        config["3d"] = true;
        config.OrgTable = table;
        config.table = table;
        config.tableForExport = table;

        config["lstSortBy"] = [];
        config["lstSortBy"].push({ Name: table["BrandsDisplayName"], Value: "Brands" });
        config["lstSortBy"].push({ Name: table["StatementsDisplayName"], Value: "Statements" });
        config["lstSortByItems"] = [];
        config["lstWave"] = [];
        table.Waves.forEach(x =>
        {
          config["lstWave"].push({ Name: x.Name, Value: x.Index });
        });
        this.fillCategoryFilterDropDowns(table);
        //config.title = table.Title;
        FusionChartsService.renderChartFromTableMultiDimension(config, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], this.chartSelectedCategories, this.chartSelectedFilters);

      },
        (err) =>
        {
          console.log(err);
        });

  }

  /////////////
  fillCategoryFilterDropDowns(table)
  {
    this.chartCategories = [];
    this.chartFilters = [];
    let catVar = this.axesValue[0];
    table[catVar].forEach((x, i) =>
    {
      this.chartCategories.push({ Name: x.Name, Value: i });
    });

    let fltrVar = this.axesValue[2];
    table[fltrVar].forEach((x, i) =>
    {
      this.chartFilters.push({ Name: x.Name, Value: i });
    });
    this.setDefaultCodes();
  }

  /////////////
  setDefaultCodes()
  {
    this.chartSelectedCategories = null;
    this.chartSelectedFilters = this.chartFilters[0]["Value"];
  }
  /////////////
  getSelectedCategory()
  {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  ngOnDestroy()
  {
    this.subscription.forEach(x =>
    {
      x.unsubscribe();
    });
  }
}
