import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { GenericService } from '../providers/generic.service';
import { MENU } from '../../settings/menu';
import { FusionChartsService } from './fusion-charts.service';
import { StorageService } from '../providers/storage.service';

@Injectable()
export class GlobalService {
  static chartConfig =
    {
      valueLabels: true,
      decimalValues: true,
      tabularForm: true,
    }

  static path: string;
  static bank = "adcb";
  static showChartSettings;
  static menuOrientation = 'V';
  static theme: any;
  static menuItems: any = [];
  static chartConfigs: any = [];
  constructor() {
  }

  static sig(val1, val2) {
    let res = 0;
    var div = 100;
    var v1 = val1.Perc;
    var v2 = val2.Perc;
    var bas = val1.Base;
    var bas2 = val2.Base;
    if (v1 != 0 && v2 != 0 && bas != 0 && bas2 != 0) {
      let per1 = v1 / div;
      let per2 = (v2) / div;
      let P1P2 = per1 - per2;
      let perQ1 = 1 - ((v1) / div);
      let perQ2 = 1 - ((v2) / div);
      let perP1Q1 = perQ1 * per1;
      let perP2Q2 = perQ2 * per2;
      let perP1Q1_Base = perP1Q1 / bas2;
      let perP2Q2_Base = perP2Q2 / bas;
      let fresult = P1P2 / Math.sqrt(perP1Q1_Base + perP2Q2_Base);

      if (fresult >= 2.576) {
        let Test = "99";
        res = 3;
        //worksheet.Cells[k, j + list[i]-1].Value = (Char)(65 + j);
      }
      else if (fresult >= 1.96) {
        let Test = "95";
        res = 2;
      }

      else if (fresult >= 1.645) {
        let Test = "90";
        res = 1;
      }
      else if (fresult <= -2.576) {
        let Test = "99";
        res = -3;
      }
      else if (fresult <= -1.96) {
        let Test = "95";
        res = -2;
      }
      else if (fresult <= -1.645) {
        let Test = "90";
        res = -1;
      }
    }
    return res;
  }

  private static _chartOptions: any[];
  private static _allChartOptions: any[];
  static get chartOptions() {
    return this._chartOptions;
  }

  static set chartOptions(chartOptions) {
    this._chartOptions = chartOptions;
    this._allChartOptions = JSON.parse(JSON.stringify(chartOptions));
  }

  private static _chartOptionsObserver = new Subject();
  static chartOptionsObservable = GlobalService._chartOptionsObserver.asObservable();
  static emitChartOptionsChanges(option) {
    this._chartOptionsObserver.next(option);
  }


  private static _titleObserver = new Subject();
  static titleObservable = GlobalService._titleObserver.asObservable();
  static changeTitle(newTitle: string) {
    this._titleObserver.next(newTitle);
  }


  private static _filters: any[];
  private static _allFilters: any[];
  static get filters() {
    return this._filters;
  }

  static set filters(filters) {
    this._filters = filters;
    this._allFilters = JSON.parse(JSON.stringify(filters));
  }

  private static _filtersObserver = new Subject();
  static filtersObservable = GlobalService._filtersObserver.asObservable();
  static emitFilterChanges() {

    this._filtersObserver.next(this.filters);
  }

  private static _categorysObserver = new Subject();
  static categoryObservable = GlobalService._categorysObserver.asObservable();
  static emitCategoryChanges() {
    this._categorysObserver.next(this.filters.filter(x => x.GroupName == 'Category')[0]);
  }

  static loadFiltersNew(masterService) {
    masterService.callService("filter/load-filters", true)
      .subscribe(
      (res) => {
        
          GlobalService.filters = res;
          GlobalService.filters.forEach(filter => {
            let Selected = filter.Multiple ? [] : null;
            let Default = false;
            filter.List.forEach(filterItem => {
              if (filterItem.Selected) {
                Default = true;
                if (filter.Multiple)
                  Selected.push(filterItem.Id);
                else
                  Selected = filterItem.Id;
              }
            });
            filter.Selected = Selected;
            if (Default)
              GlobalService.filterChanged(filter);
          });
          this.emitFilterChanges(); // This is required because now only brands are available.
        });
  }


  static loadChartOptions(masterService) {
    masterService.callService("chart-options/load", true)
      .subscribe(
        (res) => {
          GlobalService.chartOptions = res;
          GlobalService.chartOptions.forEach(x => {
            if (FusionChartsService.defaultOptions[x.PropertyName]) {
              if (x.Type == 'Toggle')
                x.Selected = parseInt(FusionChartsService.defaultOptions[x.PropertyName]) == 1 ? true : false;
              else
                x.Selected = FusionChartsService.defaultOptions[x.PropertyName];
            }
            else {
              x.Selected = null;
            }
          });
        });
  }

  static filterChanged(filter) {
    if (filter.Id == 10) {
      let aa = 10;
    }

    GlobalService.filters.forEach((x, i) => {
      if (filter.Id == x.Id) { }
      else if (x.ParentGroupId == filter.Id) {
        filter.Selected = filter.Selected ? filter.Selected : [];
        x.Selected = x.Selected ? x.Selected : [];
        let filterSelected = Array.isArray(filter.Selected) ? filter.Selected : [filter.Selected];
        filterSelected = filterSelected.length == 0 ? filter.List.map(a => a.Id) : filterSelected;
        let tempSelected = Array.isArray(x.Selected) ? x.Selected : [x.Selected];

        let tempSelectedNames = [];
        tempSelected.forEach(y => {
          let record = x.List.filter(z => z.Id == y)[0];
          if (record)
            tempSelectedNames.push(record["Name"]);
        });

        x.List = GlobalService._allFilters.filter(y => y.Id == x.Id)[0]["List"].filter(y => filterSelected.find(z => y.ParentId.includes(z)) || filterSelected.length == 0).sort((a, b) => (a.Id > b.Id) ? 1 : ((b.Id > a.Id) ? -1 : 0));
        x.Selected = x.Multiple ? [] : null;

        x.List.forEach(y => {
          if (tempSelected.find(z => z == y.Id)) {
            if (x.Multiple)
              x.Selected.push(y.Id);
            else
              x.Selected = y.Id;
          }
          else if (tempSelectedNames.find(z => z == y.Name)) {
            if (x.Multiple)
              x.Selected.push(y.Id);
            else
              x.Selected = y.Id;
          }
        });

        //new code starts
        let Selected = x.Multiple ? [] : null;
        if (x.Selected)
          Selected = x.Multiple ? [x.Selected] : x.Selected;

        x.List.forEach(filterItem => {
          if (filterItem.Selected) {
            if (x.Multiple)
              Selected.push(filterItem.Id);
            else
              Selected = filterItem.Id;
          }
        });
        x.Selected = Selected;
        //new code ends

        GlobalService.filterChanged(x);
      }
    });
 }

  static resetFilters() {
    for (let f of GlobalService.filters) {
      f.List.forEach(itm => {
        itm.Selected = false;
      });
    }
  }

  static serializeFiltersNew(global = "0") {
    var strFilters = "";
    if (!GlobalService.filters)
      return strFilters;
    for (let f of GlobalService.filters) {
      if (f.Global != global)
        continue;
      if (f.GroupName == 'Category')
        continue;

      if (f.List && f.Selected && f.Visible) {
        var selectedList = Array.isArray(f.Selected) ? f.Selected : [f.Selected];// f.Selected;
        if (selectedList.length > 0) {
          let actualList = [];
          let tempVariable = null;
          f.List.forEach(x => {
            if (selectedList.find(y => y == x.Id)) {
              actualList.push(x.Value);
              if (!tempVariable)
                tempVariable = x.VariableName;
            }
          });
          strFilters += ";" + tempVariable + ":" + GenericService.toCsv(actualList);
        }
      }
    }
    return strFilters;
  }

  static capitalize(s) {
    return s[0].toUpperCase() + s.slice(1);
  }

  static getFilterString(global = false) {
    let path = GenericService.getCurrentPath();
    let meuTitle = GlobalService.getPageTitleByPath(path);

    var strFilters = "";
    if (!GlobalService.filters)
      return strFilters;
    for (let f of GlobalService.filters) {
      if (f.Global && !global)
        continue;
      else if (!f.Global && global)
        continue;
      else if (f.HideOnMenu.indexOf(meuTitle) > -1)
        continue;
      if (f.List && f.Selected && f.Visible) {
        var selectedList = Array.isArray(f.Selected) ? f.Selected : [f.Selected];// f.Selected;
        if (selectedList.length > 0) {
          let actualList = [];
          let tempVariable = null;
          f.List.forEach(x => {
            if (selectedList.find(y => y == x.Id)) {
              actualList.push(x.Name);
              if (!tempVariable)
                tempVariable = x.Label;
            }
          });
          if (tempVariable)
            strFilters += ";" + GlobalService.capitalize(tempVariable) + ":" + GenericService.toCsv(actualList);
        }
      }
    }
    return strFilters.substring(1);
  }

  static serializeAdditionalChartOptions() {
    var strFilters = "";
    if (!GlobalService.chartOptions)
      return strFilters;
    for (let f of GlobalService.chartOptions.filter(j => j["Additional"])) {
      if (f.List && f.Selected && f.Visible) {
        var selectedList = Array.isArray(f.Selected) ? f.Selected : [f.Selected];// f.Selected;
        if (selectedList.length > 0) {
          strFilters += ";" + f.PropertyName + ":" + GenericService.toCsv(selectedList);
        }
      }
    }
    return strFilters;
  }

  static getMenuItemByPath(path: string) {
    for (let item of MENU) {
      if (item.routing == path)
        return item;
      if (item.sub) {
        for (let subItem of item.sub) {
          if (subItem.routing == path)
            return item;
        }
      }
    }
  }

  static getPageTitleByPath(path: string) {
    let item = this.getMenuItemByPath(path);
    return item ? item.title :"Brand Metrics";
  }

  static showHideFilters(menu) {
    GlobalService.filters.forEach(filter => {
      if (filter.HideOnMenu.indexOf(menu) > -1)
        filter.manualHidden = true;
      else
        filter.manualHidden = false;
    });
  }
  private static _chartTransposeObserver = new Subject();
  static chartTransposeObservable = GlobalService._chartTransposeObserver.asObservable();
  static emitChartTranspose(option) {
    this._chartTransposeObserver.next(option);
  }
  private static _appSettings: any[];
  static get appSettings() {
    return this._appSettings;
  }
  static set appSettings(appSettings) {
    this._appSettings = appSettings;
  }
  private static _appSettingsObserver = new Subject();
  static appSettingsObservable = GlobalService._appSettingsObserver.asObservable();
  static emitAppSettingsChanges() {
    this._appSettingsObserver.next(this.appSettings);
  }

static loadAppSettings(masterService)
  {
    masterService.callService("appSetting/list-with-theme", false)
      .subscribe(
        (res: any) =>
        {

          GlobalService.appSettings = res.AppSettings;
          if (GlobalService.appSettings.filter(x => x["Name"] == "ShowChartSettings").length > 0)
            GlobalService.showChartSettings = GlobalService.appSettings.filter(x => x["Name"] == "ShowChartSettings")[0]["Value"];
          if (GlobalService.appSettings.filter(x => x["Name"] == "MenuOrientation").length > 0)
            GlobalService.menuOrientation = GlobalService.appSettings.filter(x => x["Name"] == "MenuOrientation")[0]["Value"];

          this.theme = res.Themes;
          this.theme.forEach(x =>
          {
            if (getComputedStyle(document.documentElement).getPropertyValue(x.Name))
              document.documentElement.style.setProperty(x.Name, x.Value);
          });

          this.emitAppSettingsChanges();
        });
  }

}
