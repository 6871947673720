import { Injectable } from '@angular/core';
import { GenericService } from './generic.service';

@Injectable()
export class DataService
{
  static groupBy(data, prop)
  {
    return data.reduce((acc, item) =>
    {
      var key = item[prop];
      if (!acc[key])
        acc[key] = [];

      acc[key].push(item);
      return acc;
    }, {});
  }
  static sortedStubsByValue(table, bannerIndex = 0, desc = true, perc = true) {
    table.Banners[bannerIndex].ListDataPoint.forEach((x, i) => x.OldIndex = i);
    let prop = perc ? 'Perc' : 'Freq';
    table.Banners[bannerIndex].ListDataPoint = table.Banners[bannerIndex].ListDataPoint.sort((n1, n2) => {
      return desc ? n2.ListValue[0][prop] - n1.ListValue[0][prop] : n1.ListValue[0][prop] - n2.ListValue[0][prop];
    });

    let stubs = table.Stubs;
    table.Stubs = [];

    table.Banners[bannerIndex].ListDataPoint.forEach(x => {
      table.Stubs.push(stubs[x.OldIndex]);
    });

    table.Banners.forEach((banner, i) => {
      if (i != bannerIndex) {
        let values = banner.ListDataPoint;
        banner.Values = [];
        table.Banners[bannerIndex].ListDataPoint.forEach(x => {
          banner.Values.push(values[x.OldIndex]);
        });
      }
    });

    return table;
  }

  static freq(data, variables, ignoreNull = true)
  {
    let obj = {};
    variables = Array.isArray(variables) ? variables : [variables];

    variables.forEach(prop =>
    {
      obj[prop] = obj[prop] || {};
    });

    data.forEach(row =>
    {
      variables.forEach(prop =>
      {
        let key = row[prop];
        if (ignoreNull && key === "")
          return;
        else
        {
          obj[prop][key] = obj[prop][key] || 0;
          obj[prop][key]++;
        }
      });
    });

    if (variables.length == 1)
      obj = obj[variables[0]];

    return obj;
  }

  static percSingleResp(data, variables, ignoreNull = true, excludedValues = [])
  {
    let obj = DataService.freq(data, variables, ignoreNull);
    if (!Array.isArray(variables) || variables.length == 1)
    {
      let total = DataService.sumOfFields(obj);
      let keys = Object.keys(obj);
      keys.forEach(key =>
      {
        if (excludedValues && excludedValues.length > 0 && excludedValues.indexOf(key) > -1)
          delete obj[key];
        else
          obj[key] = total ? obj[key] * 100 / total : 0;
      });
    }
    else
    {
      let i = 0;
      variables.forEach(vrbl =>
      {
        let innerObj = obj[vrbl];
        let total = DataService.sumOfFields(innerObj);
        let keys = Object.keys(innerObj);
        keys.forEach(key =>
        {
          if (excludedValues && excludedValues.length > 0 && excludedValues.indexOf(key) > -1)
            delete innerObj[key];
          else
            innerObj[key] = total ? innerObj[key] * 100 / total : 0;
        });

        i++;
      });
    }
    return obj;
  }

  static percMultiResp(data, variables, ignoreNull = true)
  {
    let obj = DataService.percSingleResp(data, variables, ignoreNull);
    let objPerc = {};
    variables = Array.isArray(variables) ? variables : [variables];
    if (variables.length == 1)
      objPerc[variables[0]] = obj[variables[0]]["1"] || 0;
    else
    {
      variables.forEach(vrbl =>
      {
        objPerc[vrbl] = obj[vrbl]["1"] || 0;
      });
    }

    return objPerc;
  }

  static sum(data, props)
  {
    if (Array.isArray(props))
    {
      let s = {};

      data.forEach(row =>
      {
        props.forEach(prop =>
        {
          s[prop] = s[prop] || 0;
          if (row[prop])
            s[prop] += parseFloat(row[prop]);
        });
      });

      return s;
    }

    else
    {
      let s = 0;
      data.forEach(row =>
      {
        if (row[props])
          s += parseFloat(row[props]);
      });
      return s;
    }
  }

  static sumOfFields(obj)
  {
    let keys = Object.keys(obj);
    let total = 0;
    keys.forEach(key =>
    {
      total += parseFloat(obj[key]);
    });

    return total;
  }

  static perc(obj)
  {
    let objPerc = {};
    let total = DataService.sumOfFields(obj);
    let keys = Object.keys(obj);
    keys.forEach(key =>
    {
      objPerc[key] = parseFloat(obj[key]) * 100 / total;
    });

    return objPerc;
  }

  static replaceWithVariableLabels(obj, variablesLabels)
  {
    let objNew = {};
    let keys = Object.keys(obj);
    keys.forEach(key =>
    {
      let label = (variablesLabels[key] && (variablesLabels[key].short_label || variablesLabels[key].label)) || key;
      objNew[label] = obj[key];
    });

    return objNew;
  }

  static replaceWithValueLabels(obj, valueLabels, variableName, removeNotFound = true)
  {
    valueLabels = valueLabels.filter(x => x.variable == variableName);
    let objNew = {};
    let keys = Object.keys(obj);
    keys.forEach(key =>
    {
      let vl = valueLabels.find(x => x.value == key);
      if (vl)
      {
        let label = vl.short_label || vl.label;
        objNew[label] = obj[key];
      }
      else if (!removeNotFound)
        objNew[key] = obj[key];
    });

    valueLabels.forEach(vl =>
    {
      let key = keys.find(x => x == vl.value);
      if (key != '0' && !key)
      {
        objNew[vl.label] = 0;
      }
    });

    return objNew;
  }

  static getValueObject(value, isPerc)
  {
    let v: any = {};
    if (isPerc)
      v.Perc = value || 0;
    else
      v.Freq = value || 0;

    return v;
  }

  static objToTableKeyAsBanner(obj, stubName = '', isPerc = true)
  {
    let keys = Object.keys(obj);
    let table = { Banners: [], Stubs: [{ Name: stubName }] };

    keys.forEach(key =>
    {
      let v = DataService.getValueObject(obj[key], isPerc);
      table.Banners.push({ Name: key, Values: [v] });
    });
    return table;
  }

  static objToTableKeyAsStub(obj, bannerName = '', isPerc = true)
  {
    let keys = Object.keys(obj);
    let table = { Banners: [{ Name: bannerName, Values: [] }], Stubs: [] };

    keys.forEach(key =>
    {
      table.Stubs.push({ Name: key });
      let v = DataService.getValueObject(obj[key], isPerc);
      table.Banners[0].Values.push(v);
    });

    return table;
  }

  static addBannerToTable(table, obj, bannerName, isPerc = true)
  {
    let keys = Object.keys(obj);
    keys.forEach(key =>
    {
      let stub = table.Stubs.find(x => x.Name == key);
      if (!stub)
      {
        table.Stubs.push({ Name: key });
        table.Banners.forEach(banner =>
        {
          let v = DataService.getValueObject(0, isPerc);
          banner.Values.push(v);
        });
      }
    });

    let banner = { Name: bannerName, Values: [] };
    table.Stubs.forEach(stub =>
    {
      let key = keys.find(x => x == stub.Name);
      let v = DataService.getValueObject(obj[key], isPerc);
      banner.Values.push(v);
    });

    table.Banners.push(banner);
  }

  static addStubToTable(table, obj, stubName, isPerc = true)
  {
    let stub = { Name: stubName };
    table.Stubs.push(stub);

    let keys = Object.keys(obj);
    keys.forEach(key =>
    {
      let banner = table.Banners.find(x => x.Name == key);
      if (!banner)
      {
        banner = { Name: key, Values: [] };
        table.Banners.push(banner);
        table.Stubs.forEach(() =>
        {
          let v = DataService.getValueObject(0, isPerc);
          banner.Values.push(v);
        });
      }
    });

    table.Banners.forEach(banner =>
    {
      let key = keys.find(x => x == banner.Name);
      let v = DataService.getValueObject(obj[key], isPerc);
      banner.Values.push(v);
    });
  }

  static addValues(obj1, obj2)
  {
    for (let key of Object.keys(obj2))
    {
      obj1[key] = obj1[key] || 0;
      obj1[key] += obj2[key];
    }

    return obj1;
  }

  static toLabelValues(data)
  {
    let keys = Object.keys(data);
    let labelValues = [];
    for (let key of keys)
    {
      labelValues.push({ 'label': key, 'value': data[key] });
    }
    return labelValues;
  }

  static transposeTable(table)
  {
    table = GenericService.clone(table);
    let newStubs = [];
    let newBanners = [];

    table.Stubs.forEach(stub =>
    {
      newBanners.push({ Id: stub.Id, Name: stub.Name, Color: stub.Color, Base: stub.Base, ListDataPoint: [{Mean: '',ListValue: []}] });
    });

    table.Banners.forEach((banner) =>
    {
      let newStub = { Id: banner.Id, Name: banner.Name, Color: banner.Color, Base: banner.Base };
      newStubs.push(newStub);

      for (let j = 0; j < newBanners.length; j++)
      {
        for (let k = 0; k < banner.ListDataPoint[j].ListValue.length; k++)
        {
          newBanners[j].ListDataPoint[k].ListValue.push(banner.ListDataPoint[j].ListValue[k]);
        }
      }
    });

    table.Stubs = newStubs;
    table.Banners = newBanners;
    return table;
  }

  static transposeTableNew(table)
  {
    table = GenericService.clone(table);
    let newStubs = [];
    let newBanners = [];

    table.Stubs.forEach(stub =>
    {
      newBanners.push({ Id: stub.Id, Name: stub.Name, Color: stub.Color, Base: stub.Base, ListDataPoint: [] });
    });

    table.Banners.forEach((banner, i) =>
    {
      let newStub = { Id: banner.Id, Name: banner.Name, Color: banner.Color, Base: banner.Base };
      newStubs.push(newStub);
    });


    newBanners.forEach((banner, i) =>
    {
      for (let j = 0; j < newStubs.length; j++)
      {
          let objVal = table.Banners[j]["ListDataPoint"][i];
          newBanners[i]["ListDataPoint"].push(objVal);
      }
    });

    table.Stubs = newStubs;
    table.Banners = newBanners;
    return table;
  }

  static transposeTableNewBackup(table)
  {
    table = GenericService.clone(table);
    let newStubs = [];
    let newBanners = [];

    table.Stubs.forEach(stub =>
    {
      newBanners.push({ Id: stub.Id, Name: stub.Name, Color: stub.Color, Base: stub.Base, ListDataPoint: [] });
    });

    //table.Banners.forEach((banner) =>
    //{
    //  let newStub = { Id: banner.Id, Name: banner.Name, Color: banner.Color, Base: banner.Base };
    //  newStubs.push(newStub);
    //  for (let j = 0; j < newBanners.length; j++)
    //  {
    //    newBanners[j]["ListDataPoint"].push({ ListValue: [] });
    //    let objVal = banner["ListDataPoint"][0]["ListValue"][j];
    //    newBanners[j]["ListDataPoint"][0]["ListValue"].push(objVal);
    //  }
    //});

    table.Banners.forEach((banner, i) =>
    {
      let newStub = { Id: banner.Id, Name: banner.Name, Color: banner.Color, Base: banner.Base };
      newStubs.push(newStub);
    });


    newBanners.forEach((banner, i) =>
    {
      for (let j = 0; j < newStubs.length; j++)
      {
        newBanners[i]["ListDataPoint"].push({ ListValue: [] });
        let objVal = table.Banners[j]["ListDataPoint"][i]["ListValue"][0];
        newBanners[i]["ListDataPoint"][j]["ListValue"].push(objVal);
      }
    });

    //for (let j = 0; j < newBanners.length; j++)
    //{
    //  newBanners[j]["ListDataPoint"].push({ ListValue: [] });
    //  let objVal = banner["ListDataPoint"][0]["ListValue"][j];
    //  newBanners[j]["ListDataPoint"][0]["ListValue"].push(objVal);
    //}

    table.Stubs = newStubs;
    table.Banners = newBanners;
    return table;
  }

  static transposeTableAmit(table)
  {
    table = GenericService.clone(table);
    let newStubs = [];
    let newBanners = [];

    table.Stubs.forEach(stub =>
    {
      newBanners.push({ Id: stub.Id, Name: stub.Name, Color: stub.Color, Base: stub.Base, ListDataPoint: [{ Mean: '', ListValue: [] }] });
    });

    table.Banners.forEach((banner) =>
    {
      let newStub = { Id: banner.Id, Name: banner.Name, Color: banner.Color, Base: banner.Base };
      newStubs.push(newStub);

      for (let j = 0; j < newBanners.length; j++)
      {
        for (let k = 0; k < banner.ListDataPoint[j].ListValue.length; k++)
        {
          newBanners[j].ListDataPoint[k].ListValue.push(banner.ListDataPoint[j].ListValue[k]);
        }
      }
    });

    table.Stubs = newStubs;
    table.Banners = newBanners;
    return table;
  }


  static mergeTables(table1, table2)
  {
    let obj = {};
    let tables = [table1, table2];
    for (let table of tables)
    {
      table.Banners.forEach((banner, i) =>
      {
        table.Stubs.forEach((stub, j) =>
        {
          let key = "B$" + banner.Name.toLowerCase().trim() + "_S$" + stub.Name.toLowerCase().trim();
          if (obj[key] == undefined)
          {
            obj[key] = banner.Values[j];
          }
        });
      });
    }

    let table = GenericService.clone(table1);

    table2.Stubs.forEach((stub, i) =>
    {
      let b = table.Stubs.find(x => x.Name == stub.Name);
      if (!b)
      {
        table.Stubs.push({ Id: stub.Id, Name: stub.Name, Color: stub.Color });
      }
    });

    table2.Banners.forEach((banner, i) =>
    {
      let b = table.Banners.find(x => x.Name == banner.Name);
      if (!b)
      {
        table.Banners.push({ Id: banner.Id, Name: banner.Name, Color: banner.Color, Values: [] });
      }
    });

    table.Banners.forEach((banner, i) =>
    {
      table.Stubs.forEach((stub, j) =>
      {
        let key = "B$" + banner.Name.toLowerCase().trim() + "_S$" + stub.Name.toLowerCase().trim();
        if (obj[key] != undefined)
        {
          banner.Values[j] = obj[key];
        }

        banner.Values[j] = banner.Values[j] || { Freq: 0, Perc: 0 };
      });
    });

    return table;
  }
}
