export const MENU: any = [

  {

    title: 'Home',

    country: '1,2,3',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/home'

  },

  {

    title: 'Category Penetration',

    country: '1,2,3',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/dashboard-2d/penetration'

  },

  {

    title: 'Brand Metrics',

    country: '1,2,3',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    sub: [

      {

        title: 'Awareness',

        routing: '/dashboard-2d/bf-awareness'

      },

      {

        title: 'Usage',

        routing: '/dashboard-2d/bf-usage'

      },

      {

        title: 'Multiapp usage [Monthly]',

        routing: '/dashboard-3d-multi/monthlyusers'

      },

      {

        title: 'Multiapp usage [Weekly]',

        routing: '/dashboard-3d-multi/weeklyusers'

      },

      {

        title: 'Gojek Blockers [Past 1 Month]',

        routing: '/dashboard-3d/bf-blockersP1M'

      },

      {

        title: 'Gojek Blockers [Ever Used]',

        routing: '/dashboard-3d/bf-blockers'

      },

      //{​​​​​​

      //  title: 'Brand Funnels',

      //  routing: '/funnel/funnel'

      //}​​​​​​

    ]

  },

  {

    title: 'Experience',

    country: '1,2,3',

    icon: {

      class: 'fa fa-brain',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    }

    ,

    sub: [

      {

        title: 'Overall Satisfaction',

        routing: '/dashboard-3d-multi/Satisfaction'

      },

      {

        title: 'Overall Satisfaction - Summary',

        routing: '/dashboard-2d/SatisfactionT2b'

      },
      {

        title: 'Experience Association till May-21',

        routing: '/dashboard-3d/experience-association'

      },
      {

        title: 'Satisfaction Score - Top 2 Box',

        routing: '/dashboard-3d/experience-associationnew1'

      },
      {

        title: 'Satisfaction Score - Bottom 2 Box',

        routing: '/dashboard-3d/experience-associationnew2'

      },
      //{

      //  title: 'Experience Association - Mean',

      //  routing: '/dashboard-3d/experience-associationnew3'

      //},
      {

        title: 'Product Feature Awareness',

        routing: '/dashboard-3d/featureawareness'

      },
      {

        title: 'Product Feature Usage',

        routing: '/dashboard-3d/featureusage'

      }

    ]

  },

  {

    title: 'Communication Metrics',

    country: '1,2,3',

    icon: {

      class: 'fa fa-brain',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    sub: [

      {

        title: 'Unaided Ad Awareness',

        routing: '/dashboard-2d/communication-metrics-ada'

      },
      {

        title: 'Sources of Ad Awareness',

        routing: '/dashboard-3d/sources-ada'

      },
      {

        title: 'Brand Sentiment',

        routing: '/dashboard-3d-multi/communication-metrics-bs'

      }

    ]

  },

  {

    title: 'Brand Perception',

    country: '1,2,3',

    icon: {

      class: 'fa fa-brain',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    sub: [

      {

        title: 'Brand Imagery till May-21',

        routing: '/dashboard-3d/brand-perception-bi'

      },
      {

        title: 'Brand Imagery User till May-21',

        routing: '/dashboard-3d/brand-perception-bi-user'

      },
      {

        title: 'Brand Imagery',

        routing: '/dashboard-3d/brand-perception-bi-new'

      },
      {

        title: 'Brand Imagery User',

        routing: '/dashboard-3d/brand-perception-bi-usernew'

      },
      {

        title: 'Brand Characters till May-21',

        routing: '/dashboard-3d/brand-perception-bc'

      }

    ]

  },

  {

    title: 'Brand Funnels',

    country: '1,2,3',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/funnel/funnel'

  },
  {

    title: 'Market Share',

    country: '1,2,3',

    icon: {

      class: 'fa fa-brain',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    sub: [

      {

        title: 'Market Share',

        routing: '/dashboard-3d-multi/mshare'

      },

      {

        title: 'Use Cases',

        routing: '/dashboard-3d/UseCase'

      }

    ]

  }

  ,

  {

    title: 'User Profiles',

    country: '1,2,3',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/user-profiles/tableagg'

  },

  {

    title: 'Brand Metrics',

    country: '4',
    category: '75,99,100,101,102,103,108,109,110,111,112,113,114,117,141',
    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },
    sub: [

      {

        title: 'Awareness',

        routing: '/dashboard-2d/bf-awareness'

      },

      {

        title: 'Usage',
        routing: '/dashboard-2d/usage'

      },
      {
        category: '112,117',
        title: 'Barriers',
        routing: '/dashboard-2d/blockers'

      },
    ]


  },

  {

    title: 'Brand  Perception',

    country: '4',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/dashboard-3d/brandperceptionbi'

  },

  {

    title: 'Brand Occasions',

    country: '4',

    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/dashboard-3d/brand-perception-bo'

  }
  ,
  {

    title: 'Feature Awareness & Usage',

    country: '4',
   // category: '75,99,100,101,102,103,109,111,112,113,117',
    category: '75,99,100,101,102,103,109,112,113,114,117,141',
    icon: {

      class: 'fa fa-brain',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },
    sub: [

      {
       
        title: 'Awareness',
        routing: '/dashboard-2d/aided-awareness'

      },
      {

        title: 'Past 1 Month Usage',

        routing: '/dashboard-2d/p1m-usage'

      }

    ]

  },

  {

    title: 'Feature Awareness & Usage',

    country: '4',
    //category: '75,99,100,101,102,103,108,110,111,113',
    category: '108,110,111',
    icon: {

      class: 'fa fa-brain',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },
    sub: [

      {

        title: 'Awareness',
        routing: '/dashboard-3d/aided-awareness'

      },
      {

        title: 'Past 1 Month Usage',

        routing: '/dashboard-3d/p1m-usage'

      }

    ]

  },
 
  {
    title: 'Experience',

    country: '4',
    category: '75,99,100,101,102,103,108,109,110,111,112,113,114,117,141',
    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },
    sub: [
      {
        title: 'Overall Satisfaction',
        routing: '/dashboard-3d-multi/Satisfaction'
      },
      {
        title: 'Overall Satisfaction - Summary',
        routing: '/dashboard-2d/SatisfactionT2b'
      },
      //{
      //  title: 'Experience Association',
      //  routing: '/dashboard-3d/experience-association'
      //}
    ]

  },
  {

    title: 'Media Metrics',

    country: '4',
    category: '75,99,100,101,102,103,108,109,111,112,113,114,117,141',
    icon: {

      class: 'fa fa-camera',

      bg: 'rgb(102, 104, 111)',

      color: '#fff'

    },

    routing: '/dashboard-2d/media-metrics'

  },

  //{

  //  title: 'Campaign Evaluation',

  //  country: '4',

  //  icon: {

  //    class: 'fa fa-camera',

  //    bg: 'rgb(102, 104, 111)',

  //    color: '#fff'

  //  },

  //  routing: '/user-profiles/tableagg'

  //},

  //{​​​​​​

  //  title: 'User Profiles',

  //  icon: {​​​​​​

  //    class: 'fa fa-camera',

  //    bg: 'rgb(102, 104, 111)',

  //    color: '#fff'

  //  }​​​​​​,

  //  sub: [

  //    {​​​​​​

  //      title: 'Monthly Aggregation',

  //      routing: '/user-profiles/tableagg'

  //    }​​​​​​

  //  ]   //}​​​​​​

]



