import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from 'src/app/providers/generic.service';


@Component({
  moduleId: module.id,
  selector: 'page-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class PageDashboardComponent implements OnDestroy
{
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  showmetrics: any=false;
  user: any;
  currentOrganogramId;
  config: any = [
    {
      PageUrl: "penetration",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following modes of transportation have you taken in the PAST ONE MONTH?', service: "test-jairam?groupId=1", hideFilter: false, defaultFilters: [] },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following modes of transportation have you taken in the PAST ONE WEEK?', service: "test-jairam?groupId=2" }
          ]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following ways of getting food have you used in the PAST ONE MONTH?', service: "test-jairam?groupId=3", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following ways of getting food have you used in the PAST ONE WEEK?', service: "test-jairam?groupId=4", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following activities have you used within the PAST ONE MONTH?', service: "test-jairam?groupId=5", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following activities have you used within the PAST ONE WEEK?', service: "test-jairam?groupId=6", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "4"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following payment methods activities have you done in the PAST MONTH?', service: "test-jairam?groupId=7", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following payment methods have you done in the PAST WEEK?', service: "test-jairam?groupId=8", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        }
      ]
    },
    {
      PageUrl: "bf-awareness",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "ride-hailing apps" or those apps that allow you to book rides on taxis or private hire vehicles?', service: "test-jairam?groupId=9", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false,mode:'A' },
          { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "test-jairam?groupId=10", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3', base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "test-jairam?groupId=67", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "test-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "online food delivery apps" or those apps that allow you to order food for delivery?', service: "test-jairam?groupId=16", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "test-jairam?groupId=17", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3', base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "test-jairam?groupId=68", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online food delivery brands have you ever heard / are aware of? Please select all that apply.. (Top of Mind + Spontaneous + Aided Awareness)', service: "test-jairam?groupId=31", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          ]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "express delivery" that allow you to make payments easily', service: "test-jairam?groupId=22", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "test-jairam?groupId=23", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3', base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "test-jairam?groupId=69", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following express delivery brands have you ever heard / are aware of? Please select all that apply.(Top of Mind + Spontaneous + Aided Awareness)', service: "test-jairam?groupId=36", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          ]
        },
        {
          Category: "4"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "e-wallet/ digital wallet" or those apps that allow you to send parcels or documents in a few hours?', service: "test-jairam?groupId=24", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "test-jairam?groupId=25", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3', base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "test-jairam?groupId=70", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following e-wallet/ digital wallet brands (offering pick-up and/or delivery of parcels or documents) have you ever heard or are aware of? Please select all that apply.(Top of Mind + Spontaneous + Aided Awareness)', service: "test-jairam?groupId=41", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          ]
        }
      ]
    },
    {
      PageUrl: "bf-usage",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following taxi, motorbike, car apps or online ride-hailing brands in <COUNTRY>? Please select all that apply.', service: "test-jairam?groupId=27", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST MONTH? Please select all that apply.', service: "test-jairam?groupId=28", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST WEEK? Please select all that apply.', service: "test-jairam?groupId=29", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands for motorbikes or car hire do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "test-jairam?groupId=30", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following online food delivery brands in <country>? Please select all that apply.', service: "test-jairam?groupId=32", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST MONTH? Please select all that apply.', service: "test-jairam?groupId=33", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST WEEK? Please select all that apply.', service: "test-jairam?groupId=34", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following online food delivery brands do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "test-jairam?groupId=35", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following express delivery brands? Please select all that apply.', service: "test-jairam?groupId=37", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following express delivery brands in the PAST ONE MONTH? Please select all that apply.', service: "test-jairam?groupId=38", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following express delivery brands in the PAST ONE WEEK? Please select all that apply.', service: "test-jairam?groupId=39", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following express delivery brands do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "test-jairam?groupId=40", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following e-wallet brands in <country>? Please select all that apply.', service: "test-jairam?groupId=42", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST MONTH? Please select all that apply.', service: "test-jairam?groupId=43", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST WEEK? Please select all that apply.', service: "test-jairam?groupId=44", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following e-wallet brands do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "test-jairam?groupId=45", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        }
      ]
    },
    {
      PageUrl: "monthlyusers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            //{ type: 'stackedcolumn2d', chartType: 'stacked', service: "test-jairam?groupId=65", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [], defaultFilters: [0, 1, 4] }
            {
              type: 'stackedcolumn2d', base: ' monthly active users', chartType: 'stacked', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', service: "test-jairam?groupId=65", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' monthly active users', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=81", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
        ,
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' monthly active users', ttl: 'Q: Have you used any of the following express delivery brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=83", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' monthly active users', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST ONE MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=85", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "weeklyusers",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            {
              type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=66", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=82", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        ,
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following express delivery brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=84", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        ,
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST ONE WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "test-jairam?groupId=86", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }

      ]
    },
    {
      PageUrl: "bf-blockersP1M",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{
            type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl:'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "test-jairam?groupId=58", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {} }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "test-jairam?groupId=60", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "test-jairam?groupId=121", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        }
      ]
    },
    {
      PageUrl: "bf-blockers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base:'Monthly active users & Never used ...', ttl:'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "test-jairam?groupId=59", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {} }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "test-jairam?groupId=61", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        }
      ]
    },
    {
      PageUrl: "Satisfaction",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl:'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', service: "test-jairam?groupId=46", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl:'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', service: "test-jairam?groupId=48", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl:'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', service: "test-jairam?groupId=50", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl:'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', service: "test-jairam?groupId=52", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "SatisfactionT2b",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', service: "test-jairam?groupId=87", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "test-jairam?groupId=101", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "test-jairam?groupId=105", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true,mean:true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', service: "test-jairam?groupId=88", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "test-jairam?groupId=102", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "test-jairam?groupId=106", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', service: "test-jairam?groupId=89", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "test-jairam?groupId=103", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "test-jairam?groupId=107", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', service: "test-jairam?groupId=90", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "test-jairam?groupId=104", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "test-jairam?groupId=108", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "experience-association",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=47", base: 'Base: Past 1 month users', ttl:'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table',tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=49", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=51", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    }
    , {
      PageUrl: "communication-metrics-ada",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=56", base: "Base: Respondents who're aware of the brand", ttl:'Q: Which of the following brands have you seen or heard an advertisement for in the PAST TWO WEEKS? Please select all that apply.',basecolum:true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 },  }
          ]
        }
      ]
    },
    {
      PageUrl: "communication-metrics-bs",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            {
              type: 'stackedcolumn2d', chartType: 'stacked', service: "test-jairam?groupId=57", base: " ", ttl: "Q: Have you recently spoken about the brand with anyone? 1) Yes, I have recently spoken POSITIVELY about the brand 2) Yes, I have recently spoken NEGATIVELY about the brand 3) Yes, I have recently spoken about the brand but NEITHER POSITIVE NOR NEGATIVE 4) No, I haven't recently spoken about the brand", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [], hideFilter: false,  }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=54", base: "Base: Respondents who're aware of the brand", ttl:'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bc",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "test-jairam?groupId=55", base: "Base: Respondents who're aware of the brand", ttl:'Q: Which of the following characteristics do you associate with ...?', basecolum: true, mode: 'table', tbview: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    }
  ];

  round: any = Math.round;
  toInt: any = parseInt;
  categories: any = [{ Name: 'Transport', Value: "1" }, { Name: 'Food', Value: "2" }, { Name: 'Send(express delivery)', Value: "3" }, { Name: 'Pay(e-wallet)', Value: "4" }];
  SelectedCategory: any;
  SelectedConfig: any = [];
  SelectedPageConfig: any = [];

  brands: any = [];
  SelectedBrands: any;
  statements: any = [];
  SelectedStatements: any;

  selectedTabIndex: any = 0;
  showFilters: any = false;
  showStacked: any = false;
  showcolumn2d: any = false;
  initialLoad: any = false;
  filterLabels: any;

  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute)
  {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');

    this.subscription.push(GlobalService.filtersObservable.subscribe(filters =>
    {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
    }));

    this.subscription.push(GlobalService.categoryObservable.subscribe(category =>
    {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      //this.changeCategory();
      this.run();
    }));

    this.subscription.push(GlobalService.chartOptionsObservable.subscribe(option =>
    {
      this.run();
    }));
  }

  getSelectedCategory() {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }
  ngOnInit()
  {
    this.route.params.subscribe(params =>
    {

      this.brands = [];
      this.SelectedBrands = null;;
      this.statements = [];
      this.SelectedStatements = null;

      this.selectedTabIndex = 0;
      this.showFilters = false;
      this.showStacked = false;
      this.showcolumn2d = false;

      let id = params["id"] ? params["id"] : "1";
      let pageConfig = this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];
      this.SelectedPageConfig = pageConfig;

      ///Commented on 20-08
      //this.SelectedCategory = this.config.filter(x => x["PageUrl"] == id)[0].DefaultCategory;
      //if (GlobalService.filters && this.SelectedCategory != 999)
      //  GlobalService.filters.filter(x => x.GroupName == 'Category')[0]["Selected"] = GlobalService.filters.filter(x => x.GroupName == 'Category')[0].List.filter(x => x.Value == this.SelectedCategory)[0]["Id"];
      //this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
      if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
        this.run();

      //if (this.SelectedCategory)
      //  this.run();

    });
  }

  //changeCategory()
  //{
  //  let categoryTemp = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory);
  //  if (categoryTemp.length > 0)
  //    this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
  //  this.run();
  //}

  changeTab()
  {
    this.setDefaultCodes();
    this.SelectedConfig.forEach((x, i) =>
    {
      let config = x;
      FusionChartsService.renderChartFromTableMain(config, true, this.SelectedBrands, this.SelectedStatements, this.selectedTabIndex);
    });
  }

  reloadChart()
  {
   
    this.SelectedConfig.forEach((x, i) =>
    {
      let config = x;
      FusionChartsService.renderChartFromTableMain(config, true, this.SelectedBrands, this.SelectedStatements, this.selectedTabIndex);
    });
  }

  public run()
  {
    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
    else
      this.SelectedConfig = this.SelectedPageConfig[0]["Configs"];

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();

    this.filterLabels = GlobalService.getFilterString(true);
    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.SelectedConfig.forEach((x, i) =>
    {
      let config = x;
      
      config.id = 'chart' + i;
      //if (this.ajaxSubscription)
      //  this.ajaxSubscription.unsubscribe();
      config.filters = filters;
      config.filtersGlobal = filtersGlobal;      
      config.filtersAdditional = filtersAdditional;
      config.filtersGlobalOthers = filtersGlobalOthers;

      config.loading = true;
      this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
        finalize(() =>
        {
          config.loading = false;
        }))
        .subscribe((table) =>
        {
          if (config.sortStubs)
          {
            let bannerIndex = config.sortStubs.bannerIndex || 0;
            let desc = config.sortStubs.desc || true;
            let perc = config.sortStubs.perc || true;
            table = this.chartService.sortedStubsByValueNew(table, bannerIndex, desc, perc);
          }

         
          if (!config.chartType)
          {
            config.OrgTable = table;
            config.table = table;
            this.fillStubsDropDown(config);
            this.fillBrandStatementDropDowns(table);
            config.table = DataService.transposeTableNew(table);
            config.tableForExport = DataService.transposeTableNew(table);
            config.title = table.Title;
            config.SelectedBrands = this.SelectedBrands;
            config.SelectedStatements = this.SelectedStatements;
            config.selectedTabIndex = this.selectedTabIndex;
            let baseTbl = [];
            table.Banners.forEach(banner => {
              baseTbl.push({ Name: banner.Name, Value: (banner.Base ? banner.Base : 0) });
            });
            //config.BaseStr = baseStr;
            config.BaseTbl = baseTbl;
            FusionChartsService.renderChartFromTableMain(config, true, this.SelectedBrands, this.SelectedStatements, this.selectedTabIndex);
            if (!this.showFilters)
              this.changeChartStub(config);
          }
          else if (config.chartType && config.chartType == "stacked")
          {
            config.OrgTable = table;
            config.table = table;
            this.fillStubsDropDown(config);
            config.charts = [];

            table.Stubs.forEach((x, i) =>
            {
              if (config.defaultFilters && config.defaultFilters.length > 0 && !config.defaultFilters.includes(i))
              {
              }
              else
              {
                let tempStub = JSON.parse(JSON.stringify(table["Stubs"][i]));
                let tempTable = { Stubs: [tempStub], Banners: [] };
                table.Banners.forEach((y, j) =>
                {
                  let tempBanner = JSON.parse(JSON.stringify(y));
                  let tempDataPoint = JSON.parse(JSON.stringify(tempBanner["ListDataPoint"][i]));
                  tempBanner["ListDataPoint"] = [tempDataPoint];
                  tempTable.Banners.push(tempBanner);
                });
                let baseTbl = [];
                tempTable.Banners.forEach(banner => {
                  baseTbl.push({ Name: banner.Name, Value: (banner.ListDataPoint[0].ListValue[0].Base ? banner.ListDataPoint[0].ListValue[0].Base: 0) });
                });
                config.charts.push({ title: x.Name, id: x.Id,base: 'Base: ' + x.Name + config.base, type: config.type,ttl:config.ttl,  chartOptions: config.chartOptions, table: tempTable, BaseTbl: baseTbl });
              }
            });
            config.charts.forEach((chartConfig, j) =>
            {
              FusionChartsService.renderStackedChartFromTable(chartConfig, true);
            });
            this.showStacked = true;
          }
          else if (config.chartType && config.chartType == "bannerWise")
          {
            config.table = table;
            this.fillBrandStatementDropDowns(table);
            config.table = DataService.transposeTableNew(table);
            //config.tableForExport = DataService.transposeTableNew(table);
            config.title = table.Title;
            config.SelectedBrands = this.SelectedBrands;
            config.SelectedStatements = this.SelectedStatements;
            config.selectedTabIndex = this.selectedTabIndex;
            let baseTbl = [];
            table.Banners.forEach(banner => {
              baseTbl.push({ Name: banner.Name, Value: (banner.Base ? banner.Base : 0) });
            });
            config.BaseTbl = baseTbl;
            config.charts = [];
            var brand = config.table.Title;
            config.table.Stubs.forEach((x, i) =>
            {
              let tempStub = JSON.parse(JSON.stringify(x));
              let tempTable = { Stubs: [tempStub], Banners: [] };
              config.table.Banners.forEach((y, j) =>
              {
                let tempBanner = JSON.parse(JSON.stringify(y));
                let tempDataPoint = JSON.parse(JSON.stringify(tempBanner["ListDataPoint"][i]));
                tempBanner["ListDataPoint"] = [tempDataPoint];
                tempTable.Banners.push(tempBanner);
              });
              let hideFilterTemp = config.hideFilter != null ? config.hideFilter : null;
              config.charts.push({ title: brand, id: "chart" + i,base:config.base,ttl:config.ttl, type: config.type, chartOptions: config.chartOptions, table: tempTable, BaseTbl: [baseTbl[i]], hideFilter: hideFilterTemp, defaultFilters: config.defaultFilters });
            });
            config.charts.forEach((chartConfig, j) =>
            {
              //chartConfig.table = DataService.transposeTableNew(chartConfig.table);

              chartConfig.tableForExport = DataService.transposeTableNew(chartConfig.table);
              this.fillStubsDropDownColumn(chartConfig);
              chartConfig.table = DataService.transposeTableNew(chartConfig.table);
              chartConfig.OrgTable = chartConfig.table;
              chartConfig.height = config.height;
              FusionChartsService.renderColumnChartFromTable(chartConfig, true);
              this.changeChartStub(chartConfig);
            });
            this.showcolumn2d = true;
          }
        },
          (err) =>
          {
            console.log(err);
          });
    });
  }

  changeChartStub(config)
  {
    if (!this.showStacked)
    {
      let orgTableClone = GenericService.clone(config.OrgTable);
      let finalTable = { Stubs: [], Banners: [] };
      orgTableClone.Stubs.forEach((x, i) =>
      {
        if (!config.SelectedStubs || config.SelectedStubs.filter(y => y == i).length > 0 || config.SelectedStubs.length == 0)
          finalTable.Stubs.push(x);
      });
      orgTableClone.Banners.forEach((banner, i) =>
      {
        finalTable.Banners.push(GenericService.clone(banner));
        finalTable.Banners[i]["ListDataPoint"] = [];
        banner["ListDataPoint"].forEach((dv, j) =>
        {
          if (!config.SelectedStubs || config.SelectedStubs.filter(y => y == j).length > 0 || config.SelectedStubs.length == 0)
            finalTable.Banners[i]["ListDataPoint"].push(dv);
        });
      });
      config.table = finalTable;
      FusionChartsService.renderChartFromTableMain(config, true, this.SelectedBrands, this.SelectedStatements, this.selectedTabIndex);
    }
    else
    {
      config.charts = [];
      let table = config.table;
      config.table.Stubs.forEach((x, i) =>
      {
        if (!config.SelectedStubs || config.SelectedStubs.filter(y => y == i).length > 0 || config.SelectedStubs.length == 0)
        {
          let tempStub = JSON.parse(JSON.stringify(table["Stubs"][i]));
          let tempTable = { Stubs: [tempStub], Banners: [] };
          table.Banners.forEach((y, j) =>
          {
            let tempBanner = JSON.parse(JSON.stringify(y));
            let tempDataPoint = JSON.parse(JSON.stringify(tempBanner["ListDataPoint"][i]));
            tempBanner["ListDataPoint"] = [tempDataPoint];
            tempTable.Banners.push(tempBanner);
          });
          config.charts.push({ title: x.Name, id: x.Id, type: config.type, chartOptions: config.chartOptions, table: tempTable });
        }
      });
      config.charts.forEach((chartConfig, j) =>
      {
        FusionChartsService.renderStackedChartFromTable(chartConfig, true);
      });
    }
  }

  changeChartStubColumn(config)
  {

    let orgTableClone = GenericService.clone(config.OrgTable);
    let finalTable = { Stubs: orgTableClone.Stubs, Banners: [] };
    orgTableClone.Banners.forEach((banner, i) =>
    {
      if (config.SelectedStubs.filter(y => y == i).length > 0 || config.SelectedStubs.length == 0)
        finalTable.Banners.push(GenericService.clone(banner));
    });
    config.table = finalTable;
    FusionChartsService.renderColumnChartFromTable(config, true);
  }

  fillBrandStatementDropDowns(table)
  {
    if (table.Banners[0]["ListDataPoint"][0]["ListValue"].length > 1)
    {
      table.Stubs.forEach((x, i) =>
      {
        this.brands.push({ Name: x.Name, Value: i });
      });

      table.Banners[0]["ListDataPoint"][0]["ListValue"].forEach((x, i) =>
      {
        this.statements.push({ Name: x.Category, Value: i });
      });
      this.showFilters = true;
      this.setDefaultCodes();
    }
  }

  fillStubsDropDown(config)
  {
    let list = [];
    let tempSelection = [];
    config.table.Stubs.forEach((x, i) =>
    {
      list.push({ Name: x.Name, Value: i });
      if (config.defaultFilters && config.defaultFilters.length > 0)
      {
        if (config.defaultFilters.includes(i))
          tempSelection.push(i);
      }
    });
    config.StubsDDL = list;
    if (!config.hideFilter)
      config.SelectedStubs = tempSelection;
  }

  fillStubsDropDownColumn(config)
  {
    let list = [];
    let tempSelection = [];
    config.tableForExport.Stubs.forEach((x, i) =>
    {
      list.push({ Name: x.Name, Value: i });
      if (config.defaultFilters && config.defaultFilters.length > 0)
      {
        if (config.defaultFilters.includes(i))
          tempSelection.push(i);
      }
    });
    config.StubsDDL = list;
    if (!config.hideFilter)
      config.SelectedStubs = tempSelection;
  }

  setDefaultCodes()
  {
    if (this.selectedTabIndex == 0)
    {
      this.SelectedBrands = null;
      this.SelectedStatements = this.statements[0]["Value"];
    }
    else
    {
      this.SelectedStatements = null;
      this.SelectedBrands = this.brands[0]["Value"];
    }
  }
  ImageClick() {
    this.showmetrics = !this.showmetrics;
  }
  ngOnDestroy()
  {
    this.subscription.forEach(x =>
    {
      x.unsubscribe();
    });
  }
}
