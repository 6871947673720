import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from '../../providers/generic.service';
import { StorageService } from '../../providers/storage.service';

declare var $: any;

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class PageLoginComponent
{
    model: any = {};
    loading = false;
    error = '';
    uaeDate: any;
    constructor(private router: Router, private authService: AuthService)
    {
      StorageService.remove('breadCrumb');
      StorageService.remove('user');
      setInterval(() => { this.showUAETime(); }, 1000);
    }

    showUAETime()
    {
      var d = new Date();
      var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
      this.uaeDate = new Date(utc + (3600000 * 4));
    }
    login()
    {
        this.loading = true;
        this.error = null;
        this.authService.login(this.model.userName, this.model.password)
            .subscribe(
            (user) =>
            {
                this.loading = false;
                this.router.navigate(['/dashboard-2d/bf-awareness']);
            },
            (err) =>
            {
                this.loading = false;
              this.error = "User Name or Password is incorrect!";
                if (!this.error)
                    this.error = "Unable to connect to server.";
            }
            );
    }
}
