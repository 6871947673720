import { Component, OnInit, Input, Output } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { GenericService } from '../../providers/generic.service';
import { ChartService } from '../../providers/chart.service';
import { EventEmitter } from "@angular/core";
import { retry } from 'rxjs/operators';
import { FusionChartsService } from 'src/app/providers/fusion-charts.service';
import { DataService } from 'src/app/providers/data.service';
import { ExportToCsv } from 'export-to-csv';
import pptxgen from "pptxgenjs";

@Component({
  moduleId: module.id,
  selector: 'chart-new',
  templateUrl: 'chart-new.component.html',
  styleUrls: ['chart-new.component.scss'],
})
export class ChartNewComponent {
  @Input() config: any;
  @Input() library: any;
  @Output() chartToggleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  gs = GlobalService;
  chartVisible: boolean;
  round: any = Math.round;
  symbol: any = "%";
  flagbasemin: any;
  constructor(private chartService: ChartService) {
    this.chartVisible = true;
  }

  ngOnChanges() {
    this.config.mode = 'TCtdp';
    this.config.tableForExport = this.config.tableForExport || this.config.table;
  }

  fillListSortByItems(sortBy) {
    this.config.lstSortByItems = [];
    this.config.table[sortBy].forEach((x, i) => {
      this.config.lstSortByItems.push({ Name: x.Name, Value: x.Index });
    });
    this.config.selectedSortByItem = null;
    this.sort3dTable();
  }

  sort3dTable(sort = null) {
    this.config.sortOrder = sort || this.config.sortOrder;
    this.config.table["Brands"].forEach((x, i) => {
      if (x.Index == this.config.selectedSortByItem && this.config.selectedSortBy == "Brands")
        x.sort = true;
      else
        x.sort = false;
    });
    this.config.table["Statements"].forEach((x, i) => {
      if (x.Index == this.config.selectedSortByItem && this.config.selectedSortBy == "Statements")
        x.sort = true;
      else
        x.sort = false;
    });
    if (this.config.selectedSortBy && this.config.selectedSortByItem != null && this.config.selectedSortWave != null && this.config.sortOrder)
      this.config.tableForExport = this.chartService.sortTable3d(this.config.tableForExport, this.config.selectedSortBy
        , this.config.selectedSortByItem, this.config.selectedSortWave, this.config.sortOrder);

  }

  sort2dTableBrand(brand) {
    brand.sort = brand.sort && brand.sort == 'asc' ? 'desc' : 'asc';
    let desc = brand.sort == "desc" ? true : false;
    this.config.tableForExport.Brands.forEach(x => {
      if (x.Index != brand.Index)
        x.sort = null;
    });
    this.config.tableForExport = this.chartService.sortTable2dBrand(this.config.tableForExport, brand.Index, desc);
  }

  sort2dTableWave(wave) {
    wave.sort = wave.sort && wave.sort == 'asc' ? 'desc' : 'asc';
    let desc = wave.sort == "desc" ? true : false;
    this.config.tableForExport.Waves.forEach(x => {
      if (x.Index != wave.Index)
        x.sort = null;
    });
    this.config.tableForExport = this.chartService.sortTable2dWave(this.config.tableForExport, wave.Index, desc);
  }

  roundValue(dp): any {
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimals"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }

  roundBase(dp): any {
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimalsBase"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }

  roundValueNew(waveIndex, statementIndex, brandIndex): any {
    let dp = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Perc;
    let dpav = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Avg;
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimals"]);
    var multiplier = Math.pow(10, decimals || 0);
    var multiplierdp = Math.pow(10, decimals || 1);

    if (dpav) {
      this.symbol = "";
      return Math.round(dpav * multiplierdp) / multiplierdp;
    }
    else
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }

  roundBaseNew(waveIndex, statementIndex, brandIndex): any {

    let dp = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Base;
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimalsBase"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }
  roundMean(value, precision) {

    return value.toFixed(precision);
  }
  roundTest(value, precision) {
    var multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
  }

  toggleChartToTable() {
    this.chartVisible = !this.chartVisible;
    if (!this.config.mode)
      this.config.mode = "CTtdp";

    this.config.mode = this.config.mode.replace("T", "AAA").replace("C", "BBB").replace("AAA", "C").replace("BBB", "T");
    this.chartToggleChange.emit(this.chartVisible);
  }

  transposeChart(config) {
    GlobalService.emitChartTranspose(config);
  }

  exportToCsv() {
    this.config.tableForExport = this.config.tableForExport || this.config.table;
    let count = this.config.table.Statements.length;
    let data = count > 1 ? this.chartService.tableToDataTableMultiDimension2(this.config.table) : this.chartService.tableToDataTableNew(this.config.tableForExport);
    this.chartService.exportToCsv(data, "download");
  }

  sortTableData(banner) {
    banner.sort = banner.sort && banner.sort == 'asc' ? 'desc' : 'asc';
    let desc = banner.sort == "desc" ? true : false;
    let bannerIndex = null;
    this.config.tableForExport.Banners.forEach((x, index) => {
      if (x.Id != banner.Id)
        x.sort = null;
      else
        bannerIndex = index;
    });
    if (this.config.tableForExport.Banners[0].ListDataPoint[0].ListValue.length > 1) { }
    //this.config.tableForExport = this.chartService.sortedStubsByValueNew2(this.config.tableForExport, bannerIndex, desc);
    else
      this.config.tableForExport = this.chartService.sortedStubsByValueNew(this.config.tableForExport, bannerIndex, desc);
  }

  exportToPPT(config) {

    // 1. Create a new Presentation
    let pres = new pptxgen();
    // 2. Add a Slide
    let slide = pres.addSlide();
    // 3. Add one or more objects (Tables, Shapes, Images, Text and Media) to the Slide
    slide.addText(config.title, {
      x: 0.5,
      y: 0.1,
      w: "90%",
      h: 0.3,
      margin: 0.5,
      fontFace: "Arial",
      fontSize: 10,
      color: "333333",
      bold: false,
      fill: { color: "e9ecef" },
      align: pres.AlignH.center
    });

    let type = config.type == 'msline' ? 'line' : 'bar';

    let dataChartAreaLine = [];
    let arrChartColors = [];

    config.dataFc.dataSet.forEach((x, i) => {
      //arrChartColors.push(x.color.replace("#", ""));
      let obj: any = { labels: [], values: [] };
      obj.name = x.seriesName;
      config.dataFc.categories[0].category.forEach((y, j) => {
        obj["labels"].push(y.label);
        obj["values"].push(config.dataFc.dataSet[i].data[j].value);
      });
      dataChartAreaLine.push(obj);
    });

    let hWidth = 0;
    let tempWidth = dataChartAreaLine[0]["labels"].length * 1.5;
    if (tempWidth <= 10)
      hWidth = (10 - tempWidth) / 2;
    else
      tempWidth = 10;

    let chartOptions: any = {
      x: hWidth, y: 0.1, w: tempWidth, h: 5, catAxisLabelRotate: 70, catAxisLabelFontSize: 7
      , valAxisHidden: true, showLegend: true, legendPos: 'b', showValue: true, title: config.title
      , showTitle: false, titleFontSize: 12, valGridLine: {
        style: 'none'
      }
      //,chartColors: arrChartColors
      , align: pres.AlignH.center
    };
    if (type == 'bar')
      chartOptions["barGrouping"] = 'stacked';
    slide.addChart(pres.ChartType[type], dataChartAreaLine, chartOptions);
    // 4. Save the Presentation
    pres.writeFile("download");

  }

  getBase(brandIndex, WaveIndex) {
    this.flagbasemin = Math.round(this.config.tableForExport.Data.filter(x => x.BrandIndex == brandIndex && x.WaveIndex == WaveIndex && x.StatementIndex == 0)[0]["Value"]["Base"]);
    return this.flagbasemin;
  }
  roundSig(waveIndex, statementIndex, brandIndex): any {
    let sig1 = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"];
    let sig2 = this.config.tableForExport.Data.filter(x => x.WaveIndex == (waveIndex-1) && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"];
    return this.gs.sig(sig1, sig2);
  }
  roundSigmulti(waveIndex, statementIndex, brandIndex): any {
    let sig1 = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"];
    let sig2 = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == (brandIndex-1))[0]["Value"];
    return this.gs.sig(sig1, sig2);
  }
  
  getsymbol() {
    if (this.config.mean == true)
      return "";
    else
      return "%";
  }

}
