import { Injectable } from '@angular/core';
import { throwError as observableThrowError, Observable, Subject } from 'rxjs';
import { map, catchError, debounceTime, retry } from 'rxjs/operators';
import { HttpClient, HttpResponse } from '@angular/common/http';

import { GenericService } from './generic.service';
import { MasterService } from './master.service';
import { GlobalService } from './global.service';
import { ExportToCsv } from 'export-to-csv';


@Injectable()
export class ChartService
{
  constructor(private masterService: MasterService, private http: HttpClient)
  {

  }

  public getTable(chartConfig)
  {
    var url = chartConfig.service;
    var qs = "";
    if (chartConfig.args)
      qs += "&args=" + chartConfig.args;
    if (chartConfig.filters)
      qs += "&filters=" + chartConfig.filters;
    if (chartConfig.filtersGlobal)
      qs += "&filtersglobal=" + chartConfig.filtersGlobal;
    if (chartConfig.filtersAdditional)
      qs += "&filtersAdditional=" + chartConfig.filtersAdditional;
    if (chartConfig.filtersgroup)
      qs += "&filtersgroup=" + chartConfig.filtersgroup;
    if (chartConfig.filtersGlobalOthers)
      qs += "&filtersGlobalOthers=" + chartConfig.filtersGlobalOthers;
    if (chartConfig.filtersGlobalOthers)
      qs += "&filtersGlobalmap=" + chartConfig.filtersGlobalmap;
    if (chartConfig.filtersCross)
      qs += "&filtersCross=" + chartConfig.filtersCross;
    if (chartConfig.filtersNext)
      qs += "&filtersNext=" + chartConfig.filtersNext;
    if (qs.length > 0)
    {
      if (url.indexOf('?') > -1)
        url += qs;
      else
        url += '?' + qs;
    }

    return this.masterService.callGetService(url).pipe(debounceTime(500));
  }

  tableToDataTable(table, freq = false)
  {
    let dt = [];
    table.Stubs.forEach((stub, i) =>
    {
      let row = { '': stub.Name };
      table.Banners.forEach(banner =>
      {
        row[banner.Name] = freq && banner.Values[i].Freq != undefined ? banner.Values[i].Freq : banner.Values[i].Perc;
        row[banner.Name] = row[banner.Name] + (freq && banner.Values[i].Freq != undefined ? "" : "%");
      });
      dt.push(row);
    });
    return dt;
  }

  tableToDataTableNew(table, freq = false, decimals = 1)
  {
    let dt = [];
    table.Stubs.forEach((stub, i) =>
    {
      let row = { '': stub.Name + " (" + stub.Base.toFixed(0) + ")" };
      table.Banners.forEach(banner =>
      {
        row[banner.Name] = freq && banner.ListDataPoint[i].ListValue[0].Freq.toFixed(0) != undefined ? banner.ListDataPoint[i].ListValue[0].Freq.toFixed(0) : GenericService.round(banner.ListDataPoint[i].ListValue[0].Perc, decimals);
        row[banner.Name] = row[banner.Name] + (freq && banner.ListDataPoint[i].ListValue[0].Freq.toFixed(0) != undefined ? "" : "%");
      });
      dt.push(row);
    });
    return dt;
  }
  tableToDataTableMultiDimension2(table, freq = false, decimals = 1) {
    let dt = [];
    table.Waves.forEach((wave, i) => {
      //let stubName = brand.Name + " (" + brand.Base.toFixed(0) + ")";
      let rowBase = { '': wave.Name, ' ': 'Base' };
      table.Brands.forEach((brand, j) => {
        rowBase[brand.Name] = table.Data.filter(x => x.WaveIndex == i && x.BrandIndex == j)[0]["Value"].Base;
      });
      dt.push(rowBase);
      table.Statements.forEach((statement, j) => {
        let row = { '': '', ' ': statement.Name };
        table.Brands.forEach((brand2, k) => {
          let data = table.Data.filter(x => x.WaveIndex == i && x.StatementIndex == j && x.BrandIndex == k)[0]["Value"];
          let tempValue = freq && data.Freq != undefined ? data.Freq.toFixed(0) : (GenericService.round(data.Perc, decimals) + " %");
          row[brand2.Name] = tempValue;
        });
        dt.push(row);
      });
    });
    return dt;
  }
  sortTable3d(table, orderBy: any, index, waveIndex, sortOrder = 'desc', perc = true) {
    let tempTable = JSON.parse(JSON.stringify(table));
    let orderByVar = orderBy.substring(0, orderBy.length - 1) + "Index";
    let orderVar = (orderBy == "Brands" ? "Statements" : "Brands");
    let orderVarIndex = (orderBy == "Brands" ? "Statement" : "Brand") + "Index";

    let filterData = tempTable.Data.filter(x => x[orderByVar] == index && x["WaveIndex"] == waveIndex);
    filterData.forEach((x, i) => {
      x.OldIndex = x[orderVarIndex];
    });
    let prop = perc ? 'Perc' : 'Freq';
    let sortedData = filterData.sort((n1, n2) => {
      return (sortOrder == 'desc') ? n2["Value"][prop] - n1["Value"][prop] : n1["Value"][prop] - n2["Value"][prop];
    });
    table[orderVar] = [];
    sortedData.forEach(x => {
      table[orderVar].push(tempTable[orderVar].filter(y => y.Index == x["OldIndex"])[0]);
    });
    return table;
  }

  sortTable2d(table, brandIndex, desc = true, perc = true) {
    let tempTable = JSON.parse(JSON.stringify(table));
    let filterData = tempTable.Data.filter(x => x["BrandIndex"] == brandIndex && x["StatementIndex"] == '0');

    filterData.forEach((x, i) => {
      x.OldIndex = x["WaveIndex"];
    });

    let prop = perc ? 'Perc' : 'Freq';
    let sortedData = filterData.sort((n1, n2) => {
      return desc ? n2["Value"][prop] - n1["Value"][prop] : n1["Value"][prop] - n2["Value"][prop];
    });
    table["Waves"] = [];
    sortedData.forEach(x => {
      table["Waves"].push(tempTable["Waves"].filter(y => y.Index == x["OldIndex"])[0]);
    });
    return table;
  }

  tableToDataTableNew2(table, freq = false, decimals = 1)
  {
    let dt = [];
    table.Stubs.forEach((stub, i) =>
    {
      let stubName = stub.Name + " (" + stub.Base.toFixed(0) + ")";
      let rowBase = { '': stubName, ' ': 'Base' };
      table.Banners.forEach((banner, j) =>
      {
        rowBase[banner.Name] = banner.ListDataPoint[i].ListValue[0].Base.toFixed(0);
      });
      dt.push(rowBase);
      table.Banners[0].ListDataPoint[0].ListValue.forEach((lv, j) =>
      {
        //let stubName = j == 0 ? stub.Name + " (" + stub.Base + ")" : "";
        let row = { '': '', ' ': lv.Category };
        table.Banners.forEach((banner, k) =>
        {
          let tempValue = freq && banner.ListDataPoint[i].ListValue[j].Freq != undefined ? banner.ListDataPoint[i].ListValue[j].Freq.toFixed(0) : (GenericService.round(banner.ListDataPoint[i].ListValue[j].Perc, decimals) + " %");
          row[banner.Name] = tempValue;
        });
        dt.push(row);
      });
    });
    return dt;
  }

  dataTableToTable(dt)
  {
    let table = { Banners: [], Stubs: [] };

    if (dt.length == 0)
      return table;

    let keys = []; let firstKey;
    for (let row of dt)
    {
      let j = 0;
      for (var key in row)
      {
        if (j == 0)
        {
          firstKey = key;
          j++;
          continue;
        }

        keys.push(key);
      }
    }

    keys = Array.from(new Set(keys));
    keys.forEach(x =>
    {
      table.Banners.push({ Name: x, Values: [] });
    });

    dt.forEach((row, i) =>
    {
      table.Stubs.push({ Name: row[firstKey] });
    });

    table.Banners.forEach((banner, i) =>
    {
      table.Stubs.forEach((stub, j) =>
      {
        let value = 0;
        if (dt[j][banner.Name])
          value = parseFloat(dt[j][banner.Name].toString().replace(",", "")) || 0;
        banner.Values[j] = { Perc: value };
      });
    });

    return table;
  }

  sortBanners(table, bannerOrders, desc = false)
  {
    table.Banners = table.Banners.sort(function (a, b)
    {
      let x = isNaN(a.Name) ? a.Name : Number(a.Name);
      let y = isNaN(b.Name) ? b.Name : Number(b.Name);

      if (bannerOrders)
        return desc ? bannerOrders[y] - bannerOrders[x] : bannerOrders[x] - bannerOrders[y];
      else
        return desc ? y - x : x - y;
    });
    return table;
  }

  sortStubs(table, stubOrders, desc = false)
  {
    table.Banners.forEach(banner =>
    {
      banner.StubValueMap = banner.StubValueMap || {};
      table.Stubs.forEach((stub, j) =>
      {
        banner.StubValueMap[stub.Name] = banner.Values[j];
      });
    });

    table.Stubs = table.Stubs.sort(function (a, b)
    {
      let x = isNaN(a.Name) ? a.Name : Number(a.Name);
      let y = isNaN(b.Name) ? b.Name : Number(b.Name);
      if (stubOrders)
      {
        return desc ? stubOrders[y] - stubOrders[x] : stubOrders[x] - stubOrders[y];
      }
      else
        return desc ? y - x : x - y;
    });

    table.Banners.forEach(banner =>
    {
      banner.Values = [];
      table.Stubs.forEach((stub, j) =>
      {
        banner.Values.push(banner.StubValueMap[stub.Name]);
      });
    });

    return table;
  }

  sortedStubsByValue(table, bannerIndex = 0, desc = true, perc = true)
  {
    table.Banners[bannerIndex].Values.forEach((x, i) => x.OldIndex = i);
    let prop = perc ? 'Perc' : 'Freq';
    table.Banners[bannerIndex].Values = table.Banners[bannerIndex].Values.sort((n1, n2) =>
    {
      return desc ? n2[prop] - n1[prop] : n1[prop] - n2[prop];
    });

    let stubs = table.Stubs;
    table.Stubs = [];

    table.Banners[bannerIndex].Values.forEach(x =>
    {
      table.Stubs.push(stubs[x.OldIndex]);
    });

    table.Banners.forEach((banner, i) =>
    {
      if (i != bannerIndex)
      {
        let values = banner.Values;
        banner.Values = [];
        table.Banners[bannerIndex].Values.forEach(x =>
        {
          banner.Values.push(values[x.OldIndex]);
        });
      }
    });

    return table;
  }

  sortedStubsByValueNew(table, bannerIndex = 0, desc = true, perc = true)
  {
    table.Banners[bannerIndex].ListDataPoint.forEach((x, i) =>
      x.OldIndex = i
    );
    let prop = perc ? 'Perc' : 'Freq';

    table.Banners[bannerIndex].ListDataPoint = table.Banners[bannerIndex].ListDataPoint.sort((n1, n2) =>
    {
      return desc ? n2.ListValue[0][prop] - n1.ListValue[0][prop] : n1.ListValue[0][prop] - n2.ListValue[0][prop];
    });

    let stubs = table.Stubs;
    table.Stubs = [];

    table.Banners[bannerIndex].ListDataPoint.forEach(x =>
    {
      table.Stubs.push(stubs[x.OldIndex]);
    });

    table.Banners.forEach((banner, i) =>
    {
      if (i != bannerIndex)
      {
        let listDataPoint = banner.ListDataPoint;
        banner.ListDataPoint = [];
        table.Banners[bannerIndex].ListDataPoint.forEach(x =>
        {
          banner.ListDataPoint.push(listDataPoint[x.OldIndex]);
        });
      }
    });
    return table;
  }

  sortedStubsByValueNew2(table, bannerIndex = 0, desc = true, perc = true) {
    table.Banners[bannerIndex].ListDataPoint.forEach((x, i) => {
      x.ListValue.forEach((y, j) => {
        y.OldIndex = j;
      });
    });

    let prop = perc ? 'Perc' : 'Freq';

    table.Banners[bannerIndex].ListDataPoint.forEach((x, i) => {
      x.ListValue = x.ListValue.sort((n1, n2) => {
        return desc ? n2[prop] - n1[prop] : n1[prop] - n2[prop];
      });
    });

    table.Banners.forEach((x, i) => {
      if (bannerIndex != i) {
        let ListDataPoint = JSON.parse(JSON.stringify(x.ListDataPoint));
        table.Banners[bannerIndex].ListDataPoint.forEach((y, j) => {
          x.ListDataPoint[j].ListValue = [];
          y.ListValue.forEach((z, k) => {
            x.ListDataPoint[j].ListValue.push(ListDataPoint[j].ListValue[z.OldIndex]);
          });
        });
      }
    });

    return table;
  }
  sortTable2dBrand(table, brandIndex, desc = true, perc = true) {
    let tempTable = JSON.parse(JSON.stringify(table));
    let filterData = tempTable.Data.filter(x => x["BrandIndex"] == brandIndex && x["StatementIndex"] == '0');

    filterData.forEach((x, i) => {
      x.OldIndex = x["WaveIndex"];
    });

    let prop = perc ? 'Perc' : 'Freq';
    let sortedData = filterData.sort((n1, n2) => {
      return desc ? n2["Value"][prop] - n1["Value"][prop] : n1["Value"][prop] - n2["Value"][prop];
    });
    table["Waves"] = [];
    sortedData.forEach(x => {
      table["Waves"].push(tempTable["Waves"].filter(y => y.Index == x["OldIndex"])[0]);
    });
    return table;
  }

  sortTable2dWave(table, waveIndex, desc = true, perc = true) {
    let tempTable = JSON.parse(JSON.stringify(table));
    let filterData = tempTable.Data.filter(x => x["WaveIndex"] == waveIndex && x["StatementIndex"] == '0');

    filterData.forEach((x, i) => {
      x.OldIndex = x["BrandIndex"];
    });

    let prop = perc ? 'Perc' : 'Freq';
    let sortedData = filterData.sort((n1, n2) => {
      return desc ? n2["Value"][prop] - n1["Value"][prop] : n1["Value"][prop] - n2["Value"][prop];
    });
    table["Brands"] = [];
    sortedData.forEach(x => {
      table["Brands"].push(tempTable["Brands"].filter(y => y.Index == x["OldIndex"])[0]);
    });
    return table;
  }

  exportToCsv(data, title)
  {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
      filename: title
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };
    const csvExporter = new ExportToCsv(options);
    csvExporter.generateCsv(data);
  }

  static getColor(index)
  {
    if (index == undefined || index == null || index < 0 || this.colors.length <= index)
      return GenericService.getRandomColorHex();
    else
      return ChartService.colors[index];
  }

  static colors = [
    '#969393',
    '#ba1b19',
    '#EEB500',
    '#007681',
    '#E87722',
    '#8064A2',
    '#C0504D',
    '#F79646',
    '#71B2C9',
    '#B7BF12',
    '#888B8D',
    '#F1BE48',
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#316395',
    '#994499',
    '#22AA99',
    '#6633CC',
    '#E67300',
    '#252d47',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
    "#3e95cd",
    "#8e5ea2",
    "#3cba9f",
    "#e8c3b9",
    "#c45850",
  ]
}
