import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from 'src/app/providers/generic.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
//import { CHART_CONFIGS } from 'src/settings/chart-config';

@Component({
  moduleId: module.id,
  selector: 'page-dashboard-cross',
  templateUrl: './dashboard-cross.component.html',
  styleUrls: ['./dashboard-cross.component.scss']
})
export class PageDashboardcrossComponent implements OnDestroy
{
  showManageChartDimension = false;
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  showmetrics: any = false;
  user: any;
  filterLabels: any;
  config: any = [
    {
      
      PageUrl: "crossanalysis",
      DefaultCategory: 12
      , ChartConfigs: [
        {
          Category: "12"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "CrossAnalysis?groupId=47", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=49", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=51", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plz-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'T', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },
  ];

  round: any = Math.round;
  toInt: any = parseInt;

  SelectedCategory: any;
  SelectedConfig: any;
  SelectedPageConfig: any = [];

  chartCategories: any = [];
  chartSelectedCategories: any;

  chartFilters: any = [];
  chartSelectedFilters: any;
  initialLoad: any = false;

  waves: any = [];
  SelectedWave: any;

  axesValue = [
  ];

  axes = [
    { value: 'Category(x-Axis)', disabled: true },
    { value: 'Series(Y-Axis)', disabled: true },
    { value: 'Filters', disabled: true }
  ];

  lstSortBy: any = [];
  selectedSortBy: any;

  lstSortByItems: any = [];
  selectedSortByItem: any;

  lstSortOrder: any = [];

  drop(event: CdkDragDrop<string[]>)
  {
    moveItemInArray(this.axesValue, event.previousIndex, event.currentIndex);
    this.changeDimension();
  }

  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute)
  {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.subscription.push(GlobalService.filtersObservable.subscribe(filters =>
    {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
    }));
    this.subscription.push(GlobalService.categoryObservable.subscribe(category =>
    {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      this.run();
    }));
    this.subscription.push(GlobalService.chartOptionsObservable.subscribe(option =>
    {
      this.run();
    }));
    this.subscription.push(GlobalService.chartTransposeObservable.subscribe(option =>
    {
      let tempAxesValue = JSON.parse(JSON.stringify(this.axesValue));
      this.axesValue[0] = tempAxesValue[1];
      this.axesValue[1] = tempAxesValue[0];
      this.changeDimension();
    }));
  }

  ngOnInit()
  {
    this.route.params.subscribe(params =>
    {
      this.chartCategories = [];
      this.chartFilters = [];

      let id = params["id"] ? params["id"] : "1";
      let pageConfig = this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];
      this.SelectedPageConfig = pageConfig;
      if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
        this.run();
    });
  }

  changeDimension()
  {
    let config = this.SelectedConfig;
    this.fillCategoryFilterDropDowns(config.table);
    FusionChartsService.renderChartFromTableMultiDimension(config, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], this.chartSelectedCategories, this.chartSelectedFilters);
  }

  reloadChart()
  {
    let config = this.SelectedConfig;
    FusionChartsService.renderChartFromTableMultiDimension(config, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], this.chartSelectedCategories, this.chartSelectedFilters);
  }

  public run()
  {
    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"][0]));
    else
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig[0]["Configs"][0]));

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();


    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();

    let config = this.SelectedConfig;    
    config.filters = filters;
    config.filtersGlobal = filtersGlobal;
    config.filtersAdditional = filtersAdditional;
    config.filtersgroup = filtersGlobalgroup;
    config.filtersGlobalOthers = filtersGlobalOthers;
    this.filterLabels = GlobalService.getFilterString(true);
    config.loading = true;
    this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
      finalize(() =>
      {
        config.loading = false;
      }))
      .subscribe((table) =>
      {
        config.loading = false;
        this.axesValue.push("Brands");
        this.axesValue.push("Waves");
        this.axesValue.push("Statements");

        config["3d"] = true;
        config.OrgTable = table;
        config.table = table;
        config.tableForExport = table;

        config["lstSortBy"] = [];
        config["lstSortBy"].push({ Name: table["BrandsDisplayName"], Value: "Brands" });
        config["lstSortBy"].push({ Name: table["StatementsDisplayName"], Value: "Statements" });
        config["lstSortByItems"] = [];
        config["lstWave"] = [];
        table.Waves.forEach(x =>
        {
          config["lstWave"].push({ Name: x.Name, Value: x.Index });
        });
        this.fillCategoryFilterDropDowns(table);
        //config.title = table.Title;
        FusionChartsService.renderChartFromTableMultiDimension(config, true, this.axesValue[0], this.axesValue[1], this.axesValue[2], this.chartSelectedCategories, this.chartSelectedFilters);

      },
        (err) =>
        {
          console.log(err);
        });

  }

  /////////////
  fillCategoryFilterDropDowns(table)
  {
    this.chartCategories = [];
    this.chartFilters = [];
    let catVar = this.axesValue[0];
    table[catVar].forEach((x, i) =>
    {
      this.chartCategories.push({ Name: x.Name, Value: i });
    });

    let fltrVar = this.axesValue[2];
    table[fltrVar].forEach((x, i) =>
    {
      this.chartFilters.push({ Name: x.Name, Value: i });
    });
    this.setDefaultCodes();
  }

  /////////////
  setDefaultCodes()
  {
    this.chartSelectedCategories = null;
    this.chartSelectedFilters = this.chartFilters[0]["Value"];
  }
  /////////////
  getSelectedCategory()
  {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  ngOnDestroy()
  {
    this.subscription.forEach(x =>
    {
      x.unsubscribe();
    });
  }
}
