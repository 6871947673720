import { NgModule }                  from '@angular/core';
import { CommonModule }              from '@angular/common';
import { HttpModule }                from '@angular/http';
import { RouterModule }              from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; 
import { ChartComponent } from './chart/chart.component';
import { PipesModule }          from "../pipes/pipes.module";
import { NiComponentsModule }              from '../ni-components/ni-components.module';
import { ChartNewComponent } from './chart-new/chart-new.component';
import { ChartNewmshareComponent } from './chart-newmshare/chart-newmshare.component';
import { NgSelectModule } from '@ng-select/ng-select';
@NgModule({
    declarations: [
      ChartComponent, ChartNewComponent, ChartNewmshareComponent
    ],
    exports: [
      ChartComponent, ChartNewComponent, ChartNewmshareComponent
    ],
    imports: [
        HttpModule,
        CommonModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        NiComponentsModule, 
      PipesModule,
      NgSelectModule
    ]
})
export class CustomComponentsModule { }
