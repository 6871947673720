import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

/**
 * A custom Events service just like Ionic 3 Events https://ionicframework.com/docs/v3/api/util/Events/ which got removed in Ionic 5.
 *
 * @author Shashank Agrawal
 */
@Injectable({
    providedIn: 'root'
})
export class Events
{

    private channels: { [key: string]: Subject<any>; } = {};

    /**
     * Subscribe to a name and provide a single handler/observer.
     * @param name The name of the name to subscribe to.
     * @param observer The observer or callback function to listen when changes are published.
     *
     * @returns Subscription from which you can unsubscribe to release memory resources and to prevent memory leak.
     */
    subscribe(name: string, observer: (_: any) => void): Subscription
    {
        if (!this.channels[name])
        {
            // You can also use ReplaySubject with one concequence
            this.channels[name] = new Subject<any>();
        }

        return this.channels[name].subscribe(observer);
    }

    /**
     * Publish some data to the subscribers of the given name.
     * @param name The name of the name to emit data to.
     * @param data data in any format to pass on.
     */
    publish(name: string, data?: any): void
    {
        const subject = this.channels[name];
        if (!subject)
        {
            // Or you can create a new subject for future subscribers
            return;
        }

        subject.next(data);
    }

    /**
     * When you are sure that you are done with the name and the subscribers no longer needs to listen to a particular name, you can
     * destroy the observable of the name using this method.
     * @param name The name of the name to destroy.
     */
    destroy(name: string): null
    {
        const subject = this.channels[name];
        if (!subject)
        {
            return;
        }

        subject.complete();
        delete this.channels[name];
    }
}