/// <reference path="../../providers/master.service.ts" />
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { finalize } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { MasterService } from '../../providers/master.service';

@Component({
  moduleId: module.id,
  selector: 'page-change-password',
  templateUrl: './change-password.html',
  styleUrls: ['./change-password.scss']
})


export class ChangePasswordPage
{    
   
    user: any = {};    
    public requesterInformationForm: FormGroup;
    public submitAttempt: boolean = false;
    loading: boolean;
    error: string;

  constructor(private masterService: MasterService, private router: Router)
    {
        
    }
  
    ionViewWillEnter()
    {
        
    }
     submitForm()
    {      
        if (this.user.NewPassword != this.user.ConfirmNewPassword)
        {
          alert("Confirm Password doesn't match with the New Password.");
            return;
       }
       this.masterService.callService('user/updatepassword', false,"Post", this.user).pipe(
            finalize(() =>
            {
                //this.ionicService.hideLoader();
            })
        ).subscribe(() =>
        {
          alert("Saved Successfully!");
          this.router.navigate(['/login']);
        }, err =>
            {
                //this.ionicService.showErrorToast(err);
            })
    }

    ionViewDidEnter()
    {
       
    }
   
}
