import { Component } from '@angular/core';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
@Component({
  moduleId: module.id,
  selector: 'equity-pyramid',
  templateUrl: './equity-pyramid.component.html',
  styleUrls: ['./equity-pyramid.component.scss']
})

export class PageEquityPyramid
{
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  user: any;
  currentOrganogramId;
  loading: any = false;
  config: any = [
    {
      PageUrl: "funnel",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', service: "test-jairam?groupId=151", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "mshare",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', service: "Vol-jairam?groupId=191", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    }
  ];

  round: any = Math.round;
  toInt: any = parseInt;
  SelectedCategory: any;
  SelectedConfig: any = [];
  SelectedPageConfig: any = [];
  funnelChart: any = [];
  initialLoad: any = false;


  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute)
  {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');

    this.subscription.push(GlobalService.filtersObservable.subscribe(filters =>
    {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
    }));

    this.subscription.push(GlobalService.categoryObservable.subscribe(category =>
    {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      this.run();
      //this.changeCategory();
    }));
  }

  changeCategory()
  {
    this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
    this.run();
  }

  ngOnInit()
  {    
    this.route.params.subscribe(params =>
    {
      let id = params["id"] ? params["id"] : "1";
      let pageConfig = this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];
      this.SelectedPageConfig = pageConfig;
      if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
        this.run();
      //this.SelectedCategory = this.config.filter(x => x["PageUrl"] == id)[0].DefaultCategory;
      //this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];

      //if (GlobalService.filters && this.SelectedCategory != 999)
      //  GlobalService.filters.filter(x => x.GroupName == 'Category')[0]["Selected"] = GlobalService.filters.filter(x => x.GroupName == 'Category')[0].List.filter(x => x.Value == this.SelectedCategory)[0]["Id"];
      //this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
      //if (this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
      //  this.run();      
    });
  }

  public run()
  {

    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
    else
      this.SelectedConfig = this.SelectedPageConfig[0]["Configs"];

    this.funnelChart = [];
    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();

    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.SelectedConfig.forEach((x, i) =>
    {
      let config = x;
      config.id = 'chart' + i;
      //if (this.ajaxSubscription)
      //  this.ajaxSubscription.unsubscribe();
      config.filters = filters;
      config.filtersGlobal = filtersGlobal;
      config.filtersAdditional = filtersAdditional;
      config.filtersgroup = filtersGlobalgroup;
      config.filtersGlobalOthers = filtersGlobalOthers;

      this.loading = true;
      this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
        finalize(() =>
        {
          this.loading = false;
        }))
        .subscribe((table) =>
        {
          table.Stubs.forEach((stub, i) =>
          {
            let obj: any = { Category: [], List: [] };
            obj.title = stub.Name;
            table.Banners[0]["ListDataPoint"][i]["ListValue"].forEach(dataValue =>
            {
              obj.Category.push(dataValue.Category);
            });
            table.Banners.forEach((banner, j) =>
            {
              let funnel: any = { Name: banner.Name, List: [] };
              banner.ListDataPoint[i]["ListValue"].forEach((dataValue, k) =>
              {
                funnel.List.push({ Name: dataValue.Category, Value: dataValue.Perc, Base: dataValue.Base });
              });
              obj["List"].push(funnel);
            });
            this.funnelChart.push(obj);
          });
          let abc = this.funnelChart;

        },
          (err) =>
          {
            console.log(err);
          });
    });
  }

  getSelectedCategory() {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  ngOnDestroy()
  {
    this.subscription.forEach(x =>
    {
      x.unsubscribe();
    });
  }
}
