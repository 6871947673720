import { Component, OnInit, Input } from '@angular/core';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { MasterService } from '../../providers/master.service';

@Component({
    moduleId: module.id,
    selector: 'default-layout',
    templateUrl: 'default.component.html',
    styleUrls: ['../layouts.scss']
})
export class DefaultLayoutComponent implements OnInit
{
    pageTitle: any;
    boxed: boolean;
    compress: boolean;
  menuStyle: string;
  filterLabels: any;
    rtl: boolean;
    @Input() openedSidebar: boolean;
    subscription:any=[];

    constructor(private masterService: MasterService)
    {

        this.boxed = false;
        this.compress = false;
        this.menuStyle = 'style-3';
        this.rtl = false;

        var x = window.matchMedia("(max-width: 991px)");
        if (x.matches)
            this.openedSidebar = false;
        else
            this.openedSidebar = true;

        this.subscription.push(GlobalService.titleObservable.subscribe(
          title =>
          {
            this.pageTitle = title;
            GlobalService.path = GenericService.getCurrentPath();
          }
      ));
      this.subscription.push(GlobalService.filtersObservable.subscribe(filters => {
        this.filterLabels = GlobalService.getFilterString(true);
        GlobalService.loadAppSettings(this.masterService);
      }));
    }

    ngOnInit()
    {      
      GlobalService.loadFiltersNew(this.masterService);
      GlobalService.loadChartOptions(this.masterService);
    }

    getClasses()
    {
        let menu: string = (this.menuStyle);
        return {
            ['menu-' + menu]: menu,
            'boxed': this.boxed,
            'compress-vertical-navbar': this.compress,
            'open-sidebar': this.openedSidebar,
            'rtl': this.rtl
        };
    }

    sidebarState()
    {
        this.openedSidebar = !this.openedSidebar;
    }

    ngOnDestroy()
    {
      this.subscription.forEach(x => {
        x.unsubscribe();
      });

    }
}
