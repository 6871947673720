import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { GlobalService } from '../../../providers/global.service';
import { filter } from 'rxjs/operators';
import { MasterService } from '../../../providers/master.service';
import { GenericService } from '../../../providers/generic.service';
import { FusionChartsService } from 'src/app/providers/fusion-charts.service';

@Component({
  selector: 'addition-navbar',
  templateUrl: './addition-navbar.component.html',
  styleUrls: ['./addition-navbar.component.scss'],
  host: {
    '[class.addition-navbar]': 'true',
    '[class.open]': 'open',
    '(document:click)': 'documentClick($event)'
  }
})
export class AdditionNavbarComponent
{
  title: string;
  open: boolean;
  subscription: any;
  gs = GlobalService;

  constructor(private _elRef: ElementRef, private router: Router, private masterservice: MasterService)
  {
    this.title = 'Global Chart Settings';
    this.open = false;

    this.subscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)).subscribe((event: any) =>
      {
        GlobalService.path = event.url.split(";")[0];
      });
  }

  toggleNavbar(event = null)
  {
    if (event)
      event.preventDefault();
    this.open = !this.open;
  }

  toggleChange(option)
  {
    if (!option.Additional)
    {
      if (option.Type == 'Toggle')
        FusionChartsService.defaultOptions[option.PropertyName] = option.Selected == true ? "1" : "0";
      else
        FusionChartsService.defaultOptions[option.PropertyName] = option.Selected;
    }
    GlobalService.emitChartOptionsChanges(option);
  }

  //filterChange(filter)
  //{
  //  if (filter.Change && GlobalService[filter.Change])
  //    GlobalService[filter.Change](this.masterservice);
  //}

  //applyFilters()
  //{
  //  GlobalService.emitFilterChanges();
  //  this.open = false;
  //}

  //resetFilters()
  //{
  //  GlobalService.resetFilters();
  //  this.open = false;
  //}

  documentClick(event)
  {
    let clickedByFilterButton = event.target.name == 'filter-button' || (event.target.parentElement && event.target.parentElement.name == 'filter-button');

    if (clickedByFilterButton)
    {
      this.open = !this.open;
    }
    else if (this.open && !this._elRef.nativeElement.contains(event.target))
    {
      this.open = false;
    }
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
