import { Component, OnDestroy } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute } from "@angular/router";
import { debounceTime, finalize } from 'rxjs/operators';
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
import { GenericService } from 'src/app/providers/generic.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
//import { CHART_CONFIGS } from 'src/settings/chart-config';
import { Events } from 'src/app/providers/events';

@Component({
  moduleId: module.id,
  selector: 'page-dashboard-2d',
  templateUrl: './dashboard-2d.component.html',
  styleUrls: ['./dashboard-2d.component.scss']
})
export class PageDashboard2dComponent implements OnDestroy
{
  abc: any;
  filterLabels: any;
  flag:any=false;
  showManageChartDimension = false;
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  user: any;
  config: any = [
    {
      PageUrl: "home",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following modes of transportation have you taken in the PAST ONE MONTH?', service: "plznew-jairam?groupId=1", hideFilter: false, defaultFilters: [] },
            //{ type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following modes of transportation have you taken in the PAST ONE WEEK?', service: "plznew-jairam?groupId=2" }
          ]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following ways of getting food have you used in the PAST ONE MONTH?', service: "plznew-jairam?groupId=1", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following ways of getting food have you used in the PAST ONE WEEK?', service: "plznew-jairam?groupId=4", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following activities have you used within the PAST ONE MONTH?', service: "plznew-jairam?groupId=1", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following activities have you used within the PAST ONE WEEK?', service: "plznew-jairam?groupId=6", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "4"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following payment methods activities have you done in the PAST MONTH?', service: "plznew-jairam?groupId=1", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following payment methods have you done in the PAST WEEK?', service: "plznew-jairam?groupId=8", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },{
      PageUrl: "penetration",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following modes of transportation have you taken in the PAST ONE MONTH?', service: "plznew-jairam?groupId=1", hideFilter: false, defaultFilters: []},
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following modes of transportation have you taken in the PAST ONE WEEK?', service: "plznew-jairam?groupId=2" }
          ]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following ways of getting food have you used in the PAST ONE MONTH?', service: "plznew-jairam?groupId=3", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following ways of getting food have you used in the PAST ONE WEEK?', service: "plznew-jairam?groupId=4", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following activities have you used within the PAST ONE MONTH?', service: "plznew-jairam?groupId=5", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following activities have you used within the PAST ONE WEEK?', service: "plznew-jairam?groupId=6", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "4"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Which of the following payment methods activities have you done in the PAST MONTH?', service: "plznew-jairam?groupId=7", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Which of the following payment methods have you done in the PAST WEEK?', service: "plznew-jairam?groupId=8", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        }
      ]
    },
    {
      PageUrl: "bf-awareness",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "ride-hailing apps" or those apps that allow you to book rides on taxis or private hire vehicles?', service: "plznew-jairam?groupId=9", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=10", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=67", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "online food delivery apps" or those apps that allow you to order food for delivery?', service: "plznew-jairam?groupId=16", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=17", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=68", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart4',  base: 'Base: All respondents', ttl: 'Q: Which of the following online food delivery brands have you ever heard / are aware of? Please select all that apply.. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=31", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          ]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "express delivery" that allow you to make payments easily', service: "plznew-jairam?groupId=22", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=23", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=69", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart4',  base: 'Base: All respondents', ttl: 'Q: Which of the following express delivery brands have you ever heard / are aware of? Please select all that apply.(Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=36", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          ]
        },
        {
          Category: "4"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: What brand comes to your mind FIRST when we mention "e-wallet/ digital wallet" or those apps that allow you to send parcels or documents in a few hours?', service: "plznew-jairam?groupId=24", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=25", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=70", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart4',  base: 'Base: All respondents', ttl: 'Q: Which of the following e-wallet/ digital wallet brands (offering pick-up and/or delivery of parcels or documents) have you ever heard or are aware of? Please select all that apply.(Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=41", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
          ]
        },
        {
          Category: "5"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: Thinking specifically about digital payment, which is the first brand that comes to your mind?', service: "plznew-jairam?groupId=301", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=302", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=303", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "6"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: Thinking specifically about food, which is the first brand that comes to your mind?', service: "plznew-jairam?groupId=401", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=402", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=403", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "11"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: Thinking specifically about transport, which is the first brand that comes to your mind?', service: "plznew-jairam?groupId=601", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=602", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=603", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "12"
          , Configs: [
{ type: 'msline', id: 'chart1', base: 'Base: All respondents', ttl: 'Q: Thinking specifically about groceries, which is the first brand that comes to your mind?', service: "plznew-jairam?groupId=501", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
          { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=502", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=503", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
           // { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "14"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: Thinking specifically about gojek supper app, which is the first brand that comes to your mind?', service: "plznew-jairam?groupId=701", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart2', base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=702", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart3',  base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=703", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "15"
          , Configs: [{ type: 'msline', id: 'chart1',  base: 'Base: All respondents', ttl: 'Q: Thinking specifically about logistic, which is the first brand that comes to your mind?', service: "plznew-jairam?groupId=801", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart2',  base: 'Base: All respondents', ttl: 'Q: Any other brand?', service: "plznew-jairam?groupId=802", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart3', base: 'Base: All respondents', ttl: 'Q: Top of Mind + Spontaneous Awareness', service: "plznew-jairam?groupId=803", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            //{ type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands have you ever heard / are aware of in ...? Please select all that apply. (Top of Mind + Spontaneous + Aided Awareness)', service: "plznew-jairam?groupId=26", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        }
      ]
    },

    {
      PageUrl: "usage",
      DefaultCategory: "1"
      , ChartConfigs: [

        {
          Category: "5"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which, if any, of these digital payment brands would you consider use in the future?', service: "plznew-jairam?groupId=304", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Among the following digital payment brand(s) that you are aware of, which of these have you ever used?', service: "plznew-jairam?groupId=305", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Which of these Digital Payment brand(s) have you ever used in past 1 months ? Please select all that apply.', service: "plznew-jairam?groupId=306", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: How often do you use each of the following Digital Payment brand(s)? Please select 1 answer for each brand.', service: "plznew-jairam?groupId=307", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart8', base: 'Base: All respondents', ttl: 'Q: And which ONE of the following Digital Payment brands do you use MOST OFTEN?', service: "plznew-jairam?groupId=308", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },

          ]
        },
        {
          Category: "6"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which, if any, of these food brands would you consider use in the future?', service: "plznew-jairam?groupId=404", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Among the following food brand(s) that you are aware of, which of these have you ever used?', service: "plznew-jairam?groupId=405", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Which of these food brand(s) have you ever used in past 1 months ? Please select all that apply.', service: "plznew-jairam?groupId=406", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: How often do you use each of the following food brand(s)? Please select 1 answer for each brand.', service: "plznew-jairam?groupId=407", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart8', base: 'Base: All respondents', ttl: 'Q: And which ONE of the following Food brands do you use MOST OFTEN?', service: "plznew-jairam?groupId=408", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "11"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which, if any, of these transport brands would you consider use in the future?', service: "plznew-jairam?groupId=604", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Among the following transport brand(s) that you are aware of, which of these have you ever used? Please select all that apply.', service: "plznew-jairam?groupId=605", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Which of these transport brand(s) have you ever used in past 1 months ? Please select all that apply.', service: "plznew-jairam?groupId=606", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: How often do you use each of the following transport brand(s)? Please select 1 answer for each brand.', service: "plznew-jairam?groupId=607", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart8', base: 'Base: All respondents', ttl: 'Q: And which ONE of the following transport brands do you use MOST OFTEN?', service: "plznew-jairam?groupId=608", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "12"
          , Configs: [
            {
              type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: "Which, if any, of these Online grocery shopping brands would you consider use in the future?', service: "plznew-jairam?groupId=504", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false
            },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Among the following grocery shopping brand(s) that you are aware of, which of these have you ever used?', service: "plznew-jairam?groupId=505", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Which of these grocery shopping brand(s) have you ever used in past 1 months ? Please select all that apply.', service: "plznew-jairam?groupId=506", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: How often do you use each of the following grocery shopping brand(s)? Please select 1 answer for each brand.', service: "plznew-jairam?groupId=507", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart8', base: 'Base: All respondents', ttl: 'Q: And which ONE of the following grocery shopping brands do you use MOST OFTEN?', service: "plznew-jairam?groupId=508", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "14"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which, if any, of these gojek supper app brands would you consider use in the future?', service: "plznew-jairam?groupId=704", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Among the following gojek supper app brand(s) that you are aware of, which of these have you ever used?', service: "plznew-jairam?groupId=705", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Which of these gojek supper app brand(s) have you ever used in past 1 months ? Please select all that apply.', service: "plznew-jairam?groupId=706", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: How often do you use each of the following gojek supper app brand(s)? Please select 1 answer for each brand.', service: "plznew-jairam?groupId=707", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart8', base: 'Base: All respondents', ttl: 'Q: And which ONE of the following gojek supper app brands do you use MOST OFTEN?', service: "plznew-jairam?groupId=708", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Which, if any, of these logistic brands would you consider use in the future?', service: "plznew-jairam?groupId=804", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Among the following logistic brand(s) that you are aware of, which of these have you ever used?', service: "plznew-jairam?groupId=805", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Which of these logistic brand(s) have you ever used in past 1 months ? Please select all that apply.', service: "plznew-jairam?groupId=806", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: How often do you use each of the following logistic brand(s)? Please select 1 answer for each brand.', service: "plznew-jairam?groupId=807", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart8', base: 'Base: All respondents', ttl: 'Q: And which ONE of the following logistic brands do you use MOST OFTEN?', service: "plznew-jairam?groupId=808", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    },

   {
      PageUrl: "bf-usage",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following taxi, motorbike, car apps or online ride-hailing brands in <COUNTRY>? Please select all that apply.', service: "plznew-jairam?groupId=27", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST MONTH? Please select all that apply.', service: "plznew-jairam?groupId=28", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST WEEK? Please select all that apply.', service: "plznew-jairam?groupId=29", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following online ride-hailing brands for motorbikes or car hire do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "plznew-jairam?groupId=30", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following online food delivery brands in <country>? Please select all that apply.', service: "plznew-jairam?groupId=32", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST MONTH? Please select all that apply.', service: "plznew-jairam?groupId=33", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST WEEK? Please select all that apply.', service: "plznew-jairam?groupId=34", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following online food delivery brands do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "plznew-jairam?groupId=35", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following express delivery brands? Please select all that apply.', service: "plznew-jairam?groupId=37", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following express delivery brands in the PAST ONE MONTH? Please select all that apply.', service: "plznew-jairam?groupId=38", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following express delivery brands in the PAST ONE WEEK? Please select all that apply.', service: "plznew-jairam?groupId=39", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following express delivery brands do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "plznew-jairam?groupId=40", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart4', base: 'Base: All respondents', ttl: 'Q: Have you EVER used any of the following e-wallet brands in <country>? Please select all that apply.', service: "plznew-jairam?groupId=42", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart5', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST MONTH? Please select all that apply.', service: "plznew-jairam?groupId=43", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart6', base: 'Base: All respondents', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST WEEK? Please select all that apply.', service: "plznew-jairam?groupId=44", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } },
            { type: 'msline', id: 'chart7', base: 'Base: All respondents', ttl: 'Q: Which of the following e-wallet brands do you USE MOST OFTEN in [Pipe: Country]? Please select one.', service: "plznew-jairam?groupId=45", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }]
        }
      ]
    },
    {
      PageUrl: "monthlyusers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            //{ type: 'stackedcolumn2d', chartType: 'stacked', service: "plznew-jairam?groupId=65", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [], defaultFilters: [0, 1, 4] }
            {
              type: 'stackedcolumn2d', base: ' monthly active users', chartType: 'stacked', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', service: "plznew-jairam?groupId=65", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: []
            }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' monthly active users', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=81", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
        ,
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' monthly active users', ttl: 'Q: Have you used any of the following express delivery brands in the PAST MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=83", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' monthly active users', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST ONE MONTH? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=85", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "weeklyusers",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            {
              type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following taxi, motorbike, car apps or online ride-hailing brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=66", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: []
            }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following online food delivery brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=82", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        ,
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following express delivery brands in the PAST WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=84", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        ,
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', base: ' weekly active users', ttl: 'Q: Have you used any of the following e-wallet brands in the PAST ONE WEEK? Monthly loyalist = % of those who used only 1 brand in past 1 month for a product Monthly dual-appers = % of those who used 2 brands in past 1 month for a product Monthly multi-appers = % of those who used more than 2 brands in past 1 month for a product', chartType: 'stacked', service: "plznew-jairam?groupId=86", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }

      ]
    },
    {
      PageUrl: "bf-blockersP1M",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{
            type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=58", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {}
          }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=60", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        },
        {
          Category: "3"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: "Monthly active users & Haven't used ... P1M", ttl: 'Q: Which of the following are reasons or experiences for why you have not used ... in the past month?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=121", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        }
      ]
    },
    {
      PageUrl: "bf-blockers",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=59", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, hideFilter: false, sortStubs: {} }]
        },
        {
          Category: "2"
          , Configs: [{ type: 'msbar2d', id: 'chart1', base: 'Monthly active users & Never used ...', ttl: 'Q: Which of the following are reasons or experiences for why you have not used ...?', chartType: 'bannerWise', height: "500px", service: "plznew-jairam?groupId=61", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, sortStubs: {} }]
        }
      ]
    },
    {
      PageUrl: "Satisfaction",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', service: "plznew-jairam?groupId=46", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', service: "plznew-jairam?groupId=48", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', service: "plznew-jairam?groupId=50", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', base: ' Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', service: "plznew-jairam?groupId=52", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    },
    {
      PageUrl: "p1m-usage",
      DefaultCategory: 5
      , ChartConfigs: [
        {
          Category: "5"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=312", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "6"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=415", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "12"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=512", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "11"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=612", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=812", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following features have you used in the past 1 month?', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        },

      ]
    },

    {
      PageUrl: "aided-awareness",
      DefaultCategory: 5
      , ChartConfigs: [
        {
          Category: "5"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=311", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of digital payment feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "6"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=414", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of food feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "12"
          , Configs: [

            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=511", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of transport feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "11"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=611", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of groceries feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true, tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "14"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=711", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of supper app feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true,tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },
        {
          Category: "15"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=811", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of logistic feature have you ever heard of? Regardless of whether you have used them or not, please select all the feature that you are aware of.If you are aware of any other feature that are not in this list, please mention them below.', basecolum: true,  tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 }
            }
          ]
        },

      ]
    },

    {
      PageUrl: "blockers",
      DefaultCategory: 12
      , ChartConfigs: [
        {
          Category: "12"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=521", base: "Base: Respondents who're aware of the brand", ttl: 'Q: You previously said that you were aware of GoMart, but have never used it before. Could you tell us the reasons? You can select more than one option.', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 },
            }
          ]
        },
        {
          Category: "15"
          , Configs: [
            {
              type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=821", base: "Base: Respondents who're aware of the brand", ttl: 'Q: You previously said that you were aware of GoSend, but have never used it before. Could you tell us the reasons? You can select more than one option.', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 },
            }
          ]
        }
      ]
    },
    {
      PageUrl: "SatisfactionT2b",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=87", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=101", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=105", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each of the online ride-hailing brands for motorbikes or car you have used? Please select one answer for each brand?', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=88", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=102", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=106", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each online food delivery brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=89", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=103", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=107", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each express delivery brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=90", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=104", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=108", base: 'Base: Past 1 month users', ttl: 'Q: How satisfied are you with your overall experience with each e-wallet brand you have used? Please select one answer for each brand.', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "5"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=315", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following digital peyment brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=316", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following digital peyment brand?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=317", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following digital peyment brand?', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "6"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=417", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=418", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=419", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "11"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=617", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following 2-wheels online ride hailing service?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=618", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following 2-wheels online ride hailing service?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=619", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following 2-wheels online ride hailing service?', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'msline', service: "plznew-jairam?groupId=817", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'msline', service: "plznew-jairam?groupId=818", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', basecolum: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] },
            { type: 'mscolumn2d', service: "plznew-jairam?groupId=819", base: 'Base: Past 1 month users', ttl: 'Q: On a scale of 1 to 5, where 1 is very dissatisfied and 5 is very satisfied, to what extent are you satisfied with your overall experience in using the following online food delivery service?', basecolum: true, mean: true, transpose: true, chartOptions: { showPercentValues: 1, numberSuffix: '', showLegend: 1, decimals: 2, stack100Percent: 1 }, charts: [] }
          ]
        },
      ]
    },
    {
      PageUrl: "media-metrics",
      DefaultCategory: 11
      , ChartConfigs: [
        {
          Category: "11"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=620", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands {category} have you seen an advertisement for in the past 1 month?', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, }
          ]
        },
        {
          Category: "12"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=520", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands Online grocery shopping have you seen an advertisement for in the past 1 month?', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, }
          ]
        },
        {
          Category: "15"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=820", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands Logistic service have you seen an advertisement for in the past 1 month?', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, }
          ]
        },


      ]
    },

    {
      PageUrl: "experience-association",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=47", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=49", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=51", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=53", base: 'Base: Past 1 month users', ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    }
    , {
      PageUrl: "communication-metrics-ada",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=56", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands have you seen or heard an advertisement for in the PAST TWO WEEKS? Please select all that apply.', basecolum: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 }, }
          ]
        }
      ]
    },
    {
      PageUrl: "communication-metrics-bs",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            {
              type: 'stackedcolumn2d', chartType: 'stacked', service: "plznew-jairam?groupId=57", base: " ", ttl: "Q: Have you recently spoken about the brand with anyone? 1) Yes, I have recently spoken POSITIVELY about the brand 2) Yes, I have recently spoken NEGATIVELY about the brand 3) Yes, I have recently spoken about the brand but NEITHER POSITIVE NOR NEGATIVE 4) No, I haven't recently spoken about the brand", chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [], hideFilter: false,
            }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bi",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=54", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following brands would you associate with the following statements? Please select all brands that apply. You may also select None if you cannot associate any brand to the statement.', basecolum: true, mode: 'table', tbview: true, chartOptions: { showPercentValues: 1, decimals: 0 } }
          ]
        }
      ]
    },
    {
      PageUrl: "brand-perception-bc",
      DefaultCategory: 999
      , ChartConfigs: [
        {
          Category: "999"
          , Configs: [
            { type: 'msline', id: 'chart1', title: '', service: "plznew-jairam?groupId=55", base: "Base: Respondents who're aware of the brand", ttl: 'Q: Which of the following characteristics do you associate with ...?', basecolum: true, mode: 'table', tbview: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0 } }
          ]
        }
      ]
    }
  ];


  round: any = Math.round;
  toInt: any = parseInt;
  SelectedCategory: any;
  SelectedConfig: any = [];
  SelectedPageConfig: any = [];

  chartCategories: any = [];
  chartSelectedCategories: any;

  chartFilters: any = [];
  chartSelectedFilters: any;
  initialLoad: any = false;

  params: any;



  axesValue = [
  ];

  axes = [
    { value: 'Category(x-Axis)', disabled: true },
    { value: 'Series(Y-Axis)', disabled: true },
    { value: 'Filters', disabled: true }
  ];

  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute, private events: Events)
  {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.subscription.push(GlobalService.filtersObservable.subscribe(filters =>
    {
     
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
      //this.events.subscribe("chartConfigsLoaded", (res) =>
      //{
      //  //this.config = GlobalService.chartConfigs;
      //  this.run();
      //});       
    }));
    this.subscription.push(GlobalService.categoryObservable.subscribe(category =>
    {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      this.run();
    }));
    this.subscription.push(GlobalService.chartOptionsObservable.subscribe(option =>
    {
      this.run();
    }));
    this.subscription.push(GlobalService.chartTransposeObservable.subscribe(config =>
    {
      let tempAxesValue = JSON.parse(JSON.stringify(config["axesValue"]));
      config["axesValue"][0] = tempAxesValue[1];
      config["axesValue"][1] = tempAxesValue[0];
      this.changeDimension(config);
    }));

   
  }

  ngOnInit()
  {
    this.route.params.subscribe(params =>
    {
      this.params = params;
      if (this.config)
      {       
        if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
          this.run();
      }
    });
  }

  //changeDimension(config)
  //{
  //  config.chartSelectedCategories = null;
  //  this.fillCategoryFilterDropDowns(config, config.table);
  //  FusionChartsService.renderChartFromTableMultiDimension(config, true, config.axesValue[0], config.axesValue[1], null, null, null);
  //}
  changeDimension(config) {
    //config.chartSelectedCategories = null;
    //config.chartSelectedCategories1 = null;
    this.fillCategoryFilterDropDowns(config, config.table);
    FusionChartsService.renderChartFromTableMultiDimensionnew(config, true, config.axesValue[0], config.axesValue[1], null, config.chartSelectedCategories, config.chartSelectedCategories1, null);
  }
  //reloadChart(config)
  //{
  //  FusionChartsService.renderChartFromTableMultiDimension(config, true, config.axesValue[0], config.axesValue[1], null, config.chartSelectedCategories, null);
  //}
  reloadChart(config) {
    FusionChartsService.renderChartFromTableMultiDimensionnew(config, true, config.axesValue[0], config.axesValue[1], null, config.chartSelectedCategories, config.chartSelectedCategories1, null);
  }
  public run()
  {
    let path = GenericService.getCurrentPath();
    let meuTitle = GlobalService.getPageTitleByPath(path);
    if (meuTitle=="Home" )
      this.flag = true;
    else
      this.flag = false;
    this.chartCategories = [];
    this.chartFilters = [];
    let id = this.params["id"] ? this.params["id"] : "1";
    this.SelectedPageConfig= this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];      

    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"]));
    else
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig[0]["Configs"]));

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    var filtersCross = GlobalService.serializeFiltersNew("5");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();
    this.filterLabels = GlobalService.getFilterString(true);
    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.SelectedConfig.forEach((x, i) =>
    {
      let config = x;
      config.id = 'chart' + i;
      config.filters = filters;
      config.filtersGlobal = filtersGlobal;
      config.filtersCross = filtersCross;
      config.filtersAdditional = filtersAdditional;
      config.filtersgroup = filtersGlobalgroup;
      config.filtersGlobalOthers = filtersGlobalOthers;
      config.service = config.service +"&name="+this.abc;
      config.loading = true;
      this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
        finalize(() =>
        {
          config.loading = false;
        }))
        .subscribe((table) =>
        {
          
          config.axesValue = [];
          config.filterPlaceHolders = "Select To Filter";
          config.axesValue.push("Brands");
          config.axesValue.push("Waves");

          config["2d"] = true;
          config.OrgTable = table;
          config.table = table;
          config.tableForExport = table;

          this.fillCategoryFilterDropDowns(config, table);
          config.title = table.Title;
          FusionChartsService.renderChartFromTableMultiDimensionnew(config, true, config.axesValue[0], config.axesValue[1], null, null, null, null);

        },
          (err) =>
          {
            console.log(err);
          });
    });
  }
  fillCategoryFilterDropDowns(config, table) {
    config.chartCategories = [];
    config.chartCategories1 = [];
    let catVar = config.axesValue[0];
    let catVar1 = config.axesValue[1];
    table[catVar].forEach((x, i) => {
      config.chartCategories.push({ Name: x.Name, Value: i });
    });
    table[catVar1].forEach((x, i) => {
      config.chartCategories1.push({ Name: x.Name, Id: i });
    });
    this.setDefaultCodes(config);
  }
  /////////////
  //fillCategoryFilterDropDowns(config, table)
  //{
  //  config.chartCategories = [];
  //  let catVar = config.axesValue[0];
  //  table[catVar].forEach((x, i) =>
  //  {
  //    config.chartCategories.push({ Name: x.Name, Value: i });
  //  });
  //  this.setDefaultCodes(config);
  //}
  setDefaultCodes(config) {
    let temp: [];
    temp = config.chartSelectedCategories;
    config.chartSelectedCategories = config.chartSelectedCategories1;
    config.chartSelectedCategories1 = temp;

    //config.chartSelectedCategories = config.chartCategories[0]["Value"];
    //config.chartSelectedCategories1 = config.chartCategories[1]["Value"];
  }

  /////////////
  //setDefaultCodes(config)
  //{
  //  config.chartSelectedCategories = config.chartCategories[0]["Value"];
  //}
  /////////////
  getSelectedCategory()
  {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  ngOnDestroy()
  {
    this.subscription.forEach(x =>
    {
      x.unsubscribe();
    });
  }
}
