import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default/default.component';
import { ExtraLayoutComponent } from './layouts/extra/extra.component';

import { PageLoginComponent } from './pages/login/login.component';
import { AuthGuardService } from './providers/auth-guard.service';
import { PageDashboardComponent } from './pages/dashboard/dashboard.component';
import { PageEquityPyramid } from './pages/equity-pyramid/equity-pyramid.component';
import { PageTableTest } from './pages/table-test/table-test.component';
import { PageDashboard3dComponent } from './pages/dashboard-3d/dashboard-3d.component';
import { PageDashboard2dComponent } from './pages/dashboard-2d/dashboard-2d.component';
import { PageDashboard3dMultiComponent } from './pages/dashboard-3d-multi/dashboard-3d-multi.component';

import { ChangePasswordPage } from './pages/change-password/change-password';
import { PageHomeComponent } from './pages/home/home.component';


const defaultRoutes: Routes =
  [
    { path: 'dashboard-2d/:id', component: PageDashboard2dComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard-3d-multi/:id', component: PageDashboard3dMultiComponent , canActivate: [AuthGuardService] },
    { path: 'dashboard/:id', component: PageDashboardComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard-3d/:id', component: PageDashboard3dComponent, canActivate: [AuthGuardService] },
    { path: 'dashboard/:id', component: PageDashboardComponent, canActivate: [AuthGuardService] },
    { path: 'funnel/:id', component: PageEquityPyramid, canActivate: [AuthGuardService] },
    { path: 'home', component: PageHomeComponent, canActivate: [AuthGuardService] },
    { path: 'user-profiles/:id', component: PageTableTest },
    { path: 'change-password', component: ChangePasswordPage, canActivate: [AuthGuardService] },
  ];


const extraRoutes: Routes = [
  { path: 'login', component: PageLoginComponent }
];

export const routes: Routes =
  [
    {
      path: '',
      redirectTo: 'dashboard-2d/bf-awareness',
      pathMatch: 'full'
    },
    {
      path: '',
      component: DefaultLayoutComponent,
      children: defaultRoutes
    },
    {
      path: '',
      component: ExtraLayoutComponent,
      children: extraRoutes
    },
    {
      path: '**',
      component: DefaultLayoutComponent,
      children: defaultRoutes
    }
  ];

@NgModule({
  imports: [],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
