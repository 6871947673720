import { Component } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GenericService } from '../../providers/generic.service';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { AuthService } from '../../providers/auth.service';
import { Events } from '../../providers/events';
import { debounceTime, finalize } from 'rxjs/operators';
//declare var FusionCharts: any;

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class PageHomeComponent {
  abc: any;
  flagbasemin: any;
  addfilt: any;
  filterLabels: any;
  keyname1: any;
  keyname: any;
  chartCategories: any = [];
  chartSelectedCategories: any;

  chartFilters: any = [];

  data: any = {};
  mytable: any = {};
  mytablemarkt: any = {};
  jsonmytablemarkt: any = {};
  jsonmytable: any = {};
  loading: any = false;
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  user: any;
  isTable: any = false;
  params: any;
  initialLoad: any = false;

  SelectedCategory: any;
  SelectedConfig: any = [];
  SelectedPageConfig: any = [];
  flag: any;
  arrayOfKeys = [];
  dt: any = [];
  filterTable: any = [];
  config: any = [
    {
      PageUrl: "home",
      DefaultCategory: "1"
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'msline', id: 'chart1', service: "plznewmap-jairam?groupId=2401", filtersGlobal: ";country:1", country: "Singapore", filters: "" },
            { type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=191", filtersGlobal: ";country:2", country: "Singapore"},
          //{ type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=191", filtersGlobal: ";country:1", country: "Singapore", filters: "" },

          //{ type: 'msline', id: 'chart1', service: "plznew-jairam?groupId=9", filtersGlobal: ";country:2", country: "Vietnam", filters: ";S6:1" },
          //{ type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=191", filtersGlobal: ";country:2", country: "Vietnam", filters: ";S6:1" },
          //{ type: 'msline', id: 'chart1', service: "plznew-jairam?groupId=9", filtersGlobal: ";country:2", country: "Vietnam", filters: ";S6:2" },
          //{ type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=191", filtersGlobal: ";country:2", country: "Vietnam", filters: ";S6:2" },

          //{ type: 'msline', id: 'chart1', service: "plznew-jairam?groupId=9", filtersGlobal: ";country:3", country: "Thailand", filters: "" },
          //{ type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=191", filtersGlobal: ";country:3", country: "Thailand", filters: "" },

          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'msline', id: 'chart1', service: "plznewmap-jairam?groupId=2401", filtersGlobal: ";country:1", country: "Singapore", filters: "" },
              { type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=192", filtersGlobal: ";country:2", country: "Singapore" },
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'msline', id: 'chart1', service: "plznewmap-jairam?groupId=2401", filtersGlobal: ";country:1", country: "Singapore", filters: "" },
            //{ type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=192", filtersGlobal: ";country:2", country: "Singapore" },
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'msline', id: 'chart1', service: "plznewmap-jairam?groupId=2401", filtersGlobal: ";country:1", country: "Singapore", filters: "" },
            //{ type: 'stackedcolumn2d', id: 'chart5', service: "Vol-jairam?groupId=192", filtersGlobal: ";country:2", country: "Singapore" },
          ]
        }

      ]
    }
  ];
  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute, private events: Events) {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');
    this.subscription.push(GlobalService.filtersObservable.subscribe(filters => {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      //this.run();
      //this.events.subscribe("chartConfigsLoaded", (res) =>
      //{
      //  //this.config = GlobalService.chartConfigs;
      //  this.run();
      //});       
    }));
    this.subscription.push(GlobalService.categoryObservable.subscribe(category => {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      //this.run();
    }));
    this.subscription.push(GlobalService.chartOptionsObservable.subscribe(option => {
      //this.run();
    }));

  }
  ngOnInit() {
    this.route.params.subscribe(params => {
      this.params = params;
      if (this.config) {
        //if (this.SelectedCategory || this.initialLoad || (GlobalService.filters && GlobalService.filters.length > 0))
          //this.run();
      }
    });
    FusionChartsService.map();
    FusionChartsService.clickObservable.subscribe((obj: any) => {
      let cdt = GenericService.clone(this.dt);
      if (obj.dataObj.label == "Vietnam") {
        this.addfilt = ";country:2";
        this.run();
      }
      else if (obj.dataObj.label == "Singapore") {
        this.addfilt = ";country:1";
        this.run();
      }
      else if (obj.dataObj.label == "Thailand") {
        this.addfilt = ";country:3";
        this.run();
      }
      this.isTable = !this.isTable;
    });
    
  }

  removeDocument(doc) {
    this.filterTable = this.filterTable.forEach((item, index) => {
      if (item === doc) this.filterTable.splice(index, 1);
    });
  }

  callMe() {
    this.router.navigate(['table']);
    console.log(this.data.cluster);
  }

  getSelectedCategory() {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  public run() {

    let path = GenericService.getCurrentPath();
    let meuTitle = GlobalService.getPageTitleByPath(path);
    if (meuTitle == "Home")
      this.flag = true;
    else
      this.flag = false;
    this.chartCategories = [];
    this.chartFilters = [];
    let id = this.params["id"] ? this.params["id"] : "1";
    this.SelectedPageConfig = this.config.filter(x => x["PageUrl"] == meuTitle.toLowerCase())[0]["ChartConfigs"];

    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"]));
    else
      this.SelectedConfig = JSON.parse(JSON.stringify(this.SelectedPageConfig[0]["Configs"]));

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    //var filtersGlobalmap = GlobalService.serializeFiltersNew("4");
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    //var filtersGlobalmap = GlobalService.serializeAdditionalChartOptions();
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();

    this.filterLabels = GlobalService.getFilterString(true);
    this.initialLoad = true;
    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.SelectedConfig.forEach((x, i) => {
      let config = x;
      config.id = 'chart' + i;
      config.filters = filters;
      var k = filtersGlobal.replace(filtersGlobal.substring(filtersGlobal.indexOf("country") - 1, 10), this.addfilt);
      config.filtersGlobal = k;
      if (this.addfilt && this.addfilt.indexOf("country") > -1) {
        var p = config.filtersGlobal.substring(config.filtersGlobal.indexOf("country") - 1, 10)
        config.filtersGlobalmap = this.addfilt + ";Category:" + this.SelectedCategory;
      }
      else {
        var p = config.filtersGlobal.substring(config.filtersGlobal.indexOf("country") - 1, 10)
        config.filtersGlobalmap = p + ";Category:" + this.SelectedCategory;
      }


      //var p = config.filtersGlobal.Substring 
      //config.filtersGlobal.replace("")this.addfilt;
      config.filtersAdditional = filtersAdditional;
      config.filtersgroup = filtersGlobalgroup;
      config.filtersGlobalOthers = filtersGlobalOthers;
      //config.filtersGlobalmap = filtersGlobalmap;
      //  config.filtersGlobalOthers = this.filterLabels;
      config.service = config.service;
      config.loading = true;
      this.loading = true;
      if (i == 0) {
        this.ajaxSubscription = this.chartService.getTable(config).pipe(
          finalize(() => {
            config.loading = false;
            this.loading = false;
          }))
          .subscribe((res) => {
            var table = res;
            this.getsigtest(table);

            this.mytable = JSON.parse(JSON.stringify(this.jsonmytable));
            this.mytable = table;
            config.title = table.Title;

          },
            (err) => {
              console.log(err);
            });
      }
      else {
        this.ajaxSubscription = this.chartService.getTable(config).pipe(
          finalize(() => {
            config.loading = false;
            this.loading = false;
          }))
          .subscribe((res) => {
            //this.getsigtest(table);
            var table = res;
            this.mytablemarkt = JSON.parse(JSON.stringify(this.jsonmytablemarkt));
            this.mytablemarkt.Waves = table.Waves.filter(x => x.Index > table.Waves.length - 3);
            var gojek = table.Statements.filter(x=>x.Name.indexOf("Gojek")>-1)[0].Index;
            this.mytablemarkt.Brands = table.Brands;
            this.mytablemarkt.Statements = gojek;
            this.mytablemarkt.Data = table.Data.filter(x => x.WaveIndex > table.Waves.length - 3 && x.StatementIndex == gojek);
            config.title = table.Title;

          },
            (err) => {
              console.log(err);
            });
      }

    }


    );

    //});
  }
  getBase(brandIndex, WaveIndex) {
    this.flagbasemin = Math.round(this.mytable.Data.filter(x => x.BrandIndex == brandIndex && x.WaveIndex == WaveIndex && x.StatementIndex == 0)[0]["Value"]["Base"]);
    return this.flagbasemin;
  }
  getsigtest(table) {
    for (var i = 0; i < table.Brands.length; i++) {
      for (var j = 1; j < table.Waves.length; j++) {
        var v1 = table.Data.filter(z => z.BrandIndex == table.Brands[i].Index && z.WaveIndex == table.Waves[j].Index)[0].Value;
        var v2 = table.Data.filter(z => z.BrandIndex == table.Brands[i].Index && z.WaveIndex == table.Waves[j-1].Index)[0].Value;
        table.Data.filter(z => z.BrandIndex == table.Brands[i].Index && z.WaveIndex == table.Waves[j].Index)[0].Value.Sig = this.gs.sig(v1, v2);
      }
    }

    //let t= table.Brands.forEach(x => table.Waves.forEach((y, i) => {
    //  if (i > 0) {
    //    var v1 = table.Data.filter(z => z.BrandIndex == x.Index && z.WaveIndex == y.Index)[0].Value;
    //    var v2 = table.Data.filter(z => z.BrandIndex == x.Index && z.WaveIndex == (y.Index - 1))[0].Value;
    //    table.Data.filter(z => z.BrandIndex == x.Index && z.WaveIndex == y.Index)[0].Value.Sig =this.gs.sig(v1, v2);
    //  }
    //}))
    return table;
      //table.Data.filter(y => y.BrandIndex == x.Index)[0].Value.Sig = this.gs.sig());

    //return table.map((x=>))
  }
  roundSig(waveIndex, statementIndex, brandIndex): any {
    let sig = this.mytable.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Sig;
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    return sig;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }
  roundValueNew(waveIndex, statementIndex, brandIndex): any {
    let dp = this.mytable.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Perc;
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimals"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }
  roundValueNewmarket(waveIndex, statementIndex, brandIndex): any {
    let dp = this.mytablemarkt.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Perc;
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimals"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }

  roundBaseNew(waveIndex, statementIndex, brandIndex): any {

    let dp = this.config.tableForExport.Data.filter(x => x.WaveIndex == waveIndex && x.StatementIndex == statementIndex && x.BrandIndex == brandIndex)[0]["Value"].Base;
    //let decimals = this.config.chartOptions ? this.config.chartOptions.decimals : 1;
    //return GenericService.round(dp, decimals);
    let decimals = parseInt(FusionChartsService.defaultOptions["decimalsBase"]);
    var multiplier = Math.pow(10, decimals || 0);
    return Math.round(dp * multiplier) / multiplier;

    //var num = parseFloat(dp);
    //return num.toFixed(decimals);
  }
  async serviceCall(config, cnt) {
    await this.chartService.getTable(config).pipe(
      finalize(() => {
        this.arrayOfKeys = Object.keys(this.dt[0]);
        config.loading = false;
        if (this.SelectedConfig.length == (cnt + 1))
          this.loading = false;
      }))
      .subscribe((table) => {
        this.mytable = table;
        if (table.Title != 'Market Share') {
          if (table.Brands.filter(f => f.Name.indexOf("Gojek") != -1).length > 0) {
            //let brandIndex = table.Brands.findIndex(f => f.Name.indexOf("Gojek"));
            let brandIndex = table.Brands.filter(f => f.Name.indexOf("Gojek") != -1)[0].Index;
            let tb: any = {};


            let waveIndex = table.Waves.length - 2;
            let wave1 = table.Waves[waveIndex].Name;
            let title = table.Title;
            let value1 = table.Data.filter(f => f.BrandIndex == brandIndex && f.WaveIndex == waveIndex)[0].Value.Perc;
            tb.Title = title;
            tb[wave1] = value1;
            this.keyname1 = wave1;
            waveIndex = table.Waves.length - 1;
            let wave = table.Waves[waveIndex].Name;
            let value = table.Data.filter(f => f.BrandIndex == brandIndex && f.WaveIndex == waveIndex)[0].Value.Perc;

            tb[wave] = value;
            this.keyname = wave;
            //tb.Value = ;

            if (config.filters.search('S6:1') > -1)
              tb["Ho Chi Minh"] = value;
            if (config.filters.search('S6:2') > -1)
              tb["Hanoi"] = value;

            tb.Country = config.country;

            let checkItem = this.dt.filter(f => f.Title == title && f.Country == config.country);
            if (checkItem.length > 0) {
              let update = checkItem[0];
              let index = this.dt.indexOf(update);
              if (config.filters.search('S6:1') > -1)
                update["Ho Chi Minh"] = value;
              if (config.filters.search('S6:2') > -1)
                update["Hanoi"] = value;
              update[wave] += value;
              this.dt[index] = update;
            } else
              this.dt.push(tb);
          }

        }
        else {
          if (table.Statements.filter(f => f.Name.indexOf("Gojek") != -1).length > 0) {
            let statementIndex = table.Statements.filter(f => f.Name.indexOf("Gojek") != -1)[0].Id;
            let tb: any = {};
            let waveIndex = table.Waves.length - 2;
            let wave1 = table.Waves[waveIndex].Name;
            let title = "Market Share";
            let value1 = table.Data.filter(f => f.BrandIndex == 0 && f.WaveIndex == waveIndex && f.StatementIndex == statementIndex)[0].Value.Perc;
            tb.Title = title;
            tb[wave1] = value1;
            this.keyname1 = wave1;

            waveIndex = table.Waves.length - 1;
            let wave = table.Waves[waveIndex].Name;
            let value = table.Data.filter(f => f.BrandIndex == 0 && f.WaveIndex == waveIndex && f.StatementIndex == statementIndex)[0].Value.Perc;


            tb[wave] = value;
            this.keyname = wave;
            //tb.Wave = wave;
            //tb.Value = value;

            if (config.filters.search('S6:1') > -1)
              tb["Ho Chi Minh"] = value;
            if (config.filters.search('S6:2') > -1)
              tb["Hanoi"] = value;
            tb.Country = config.country;

            let checkItem = this.dt.filter(f => f.Title == title && f.Country == config.country);
            if (checkItem.length > 0) {
              let update = checkItem[0];
              let index = this.dt.indexOf(update);
              if (config.filters.search('S6:1') > -1)
                update["Ho Chi Minh"] = value;
              if (config.filters.search('S6:2') > -1)
                update["Hanoi"] = value;
              update[wave] += value;
              this.dt[index] = update;
            } else
              this.dt.push(tb);
          }
        }


      },
        (err) => {
          console.log(err);
        });
  }
  viewChange() {
    this.isTable = !this.isTable;
  }
}

