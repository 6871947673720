import { Component } from '@angular/core';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { finalize, debounceTime } from 'rxjs/operators';
import { StorageService } from '../../providers/storage.service';
import { ChartService } from '../../providers/chart.service';
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from '../../providers/data.service';
import { AuthService } from '../../providers/auth.service';
@Component({
  moduleId: module.id,
  selector: 'table-test',
  templateUrl: './table-test.component.html',
  styleUrls: ['./table-test.component.scss']
})

export class PageTableTest {
  title: string;
  gs = GlobalService;
  subscription: any = [];
  ajaxSubscription: any;
  user: any;
  currentOrganogramId;
  loading: any = false;
  config: any = [
     {
      PageUrl: "tableagg",
      DefaultCategory: 1
      , ChartConfigs: [
        {
          Category: "1"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', service: "test-table?groupId=131", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "2"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', service: "test-table?groupId=132", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "3"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', service: "test-table?groupId=133", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        },
        {
          Category: "4"
          , Configs: [
            { type: 'stackedcolumn2d', chartType: 'stacked', service: "test-table?groupId=134", transpose: true, chartOptions: { showPercentValues: 1, showLegend: 1, decimals: 0, stack100Percent: 1 }, charts: [] }
          ]
        }
      ]
    }
  ];

  round: any = Math.round;
  toInt: any = parseInt;
  toFloat: any = parseFloat;
  SelectedCategory: any;
  SelectedConfig: any = [];
  SelectedPageConfig: any = [];
  funnelChart: any = [];
  table: any = null;


  constructor(private chartService: ChartService, private router: Router, private authService: AuthService, private route: ActivatedRoute) {
    this.title = "GoJek";
    GlobalService.changeTitle(this.title);
    this.user = StorageService.get('user');

    this.subscription.push(GlobalService.filtersObservable.subscribe(filters => {
      let path = GenericService.getCurrentPath();
      let meuTitle = GlobalService.getPageTitleByPath(path);
      GlobalService.showHideFilters(meuTitle);
      this.run();
    }));

    this.subscription.push(GlobalService.categoryObservable.subscribe(category => {
      this.SelectedCategory = category["List"].filter(x => x.Id == category["Selected"])[0]["Value"];
      //this.changeCategory();
      this.run();
    }));
  }

  ngOnInit() {
    //FusionChartsService.clickObservable.subscribe((obj: any) =>
    //{
    //  if (obj.stubId == this.breadCrumb[this.breadCrumb.length - 1].Id)
    //    return;
    //  let user = this.authService.getUser();
    //   if (!user.OrganogramIds.find(x => x == parseInt(obj.stubId)))
    //    return;
    //  let stub = this.config.table.Stubs.find(x => x.Id == obj.stubId);
    //  this.breadCrumb.push({ Id: obj.stubId, Name: stub.Name });
    //  StorageService.set("breadCrumb", this.breadCrumb);
    //  this.run();
    //});
    this.route.params.subscribe(params => {
      let id = params["id"] ? params["id"] : "1";
      let pageConfig = this.config.filter(x => x["PageUrl"] == id)[0]["ChartConfigs"];
      this.SelectedPageConfig = pageConfig;

      //this.SelectedCategory = this.config.filter(x => x["PageUrl"] == id)[0].DefaultCategory;
      //this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];

      this.run();
    });
  }
  changeCategory() {
    this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
    this.run();
  }

  getSelectedCategory() {
    let catFilter = GlobalService.filters.filter(x => x.GroupName == "Category")[0];
    if (!catFilter.Visible)
      return null;
    let selectedCat = catFilter.List.filter(x => x.Id == catFilter.Selected)[0].Value;
    return selectedCat;
  }

  public run() {

    this.SelectedCategory = this.getSelectedCategory();
    if (this.SelectedPageConfig.length > 1)
      this.SelectedConfig = this.SelectedPageConfig.filter(y => y["Category"] == this.SelectedCategory)[0]["Configs"];
    else
      this.SelectedConfig = this.SelectedPageConfig[0]["Configs"];

    var filters = GlobalService.serializeFiltersNew();
    var filtersGlobal = GlobalService.serializeFiltersNew("1");
    var filtersGlobalOthers = GlobalService.serializeFiltersNew("2");
    var filtersGlobalgroup = GlobalService.serializeFiltersNew("3");
    var filtersAdditional = GlobalService.serializeAdditionalChartOptions();

    if (this.ajaxSubscription)
      this.ajaxSubscription.unsubscribe();
    this.SelectedConfig.forEach((x, i) => {
      let config = x;
      config.id = 'chart' + i;
      //if (this.ajaxSubscription)
      //  this.ajaxSubscription.unsubscribe();
      config.filters = filters;
      config.filtersGlobal = filtersGlobal;
      config.filtersAdditional = filtersAdditional;
      config.filtersgroup = filtersGlobalgroup;
      config.filtersGlobalOthers = filtersGlobalOthers;

      this.loading = true;
      this.ajaxSubscription = this.chartService.getTable(config).pipe(debounceTime(500),
        finalize(() => {
          this.loading = false;
        }))
        .subscribe((table) => {
          this.table = table;
        },
          (err) => {
            console.log(err);
          });
    });
  }
  ngOnDestroy() {
    this.subscription.forEach(x => {
      x.unsubscribe();
    });
  }
}
